// apiSlice.ts
import { FetchBaseQueryError, createApi } from '@reduxjs/toolkit/query/react'
//import { Point } from '../components/carecenter/Centers'
import { qryFuncWithSubHeader } from './ChatSlice'

// export interface profileCreateMatch {
//     iamId: string | null,
//     userName: string | null,
//     email: string | null,
//     nickname: string | null
//     isFederated: boolean | null
// } 
export interface profileCreateMatch {
    iamId: string | null,
    email: string | null,
    nickname: string | null
    isFederated: boolean | null
} 
export const profileApi = createApi({
    reducerPath: 'profileApi',
    //baseQuery: fetchBaseQuery(),
    baseQuery: qryFuncWithSubHeader,
    tagTypes: ['Profile', 'ChatUserProfile', 'ProfilePinData','Username'],
    endpoints: (builder) => ({
        //gest profile  by iam_is inserts if no matching profile found    
        getEmailConfirmationStatus:    builder.query<any, string>({
            query: (email) => {
                const qry = `/api/get/emailConfirmationStatus/?email=${email}`                
                return qry
            },            
        }),
        getLoggedInUserProfile: builder.query<any, profileCreateMatch>({
            query: (args) => {
                const iamId = args.iamId ? args.iamId : "unauthenticated"
                const qry = `/api/get/userProfile/?iamId=${iamId}&email=${args.email}&nickname=${args.nickname}&is_federated=${args.isFederated}`                
                return qry
            },
            providesTags: (result, response, updated) => {
                return [{ type: 'Profile', id: result?.id??'' }]
            }
        }),


        // getLoggedInUserProfile1: builder.query<any, profileCreateMatch>({
        //     query: (args) => {
        //         const iamId = args.iamId ? args.iamId : "unauthenticated"
        //         const qry = `/api/get/userProfile1/?iamId=${iamId}${args.isFederated?`&is_federated=${args.isFederated}`:''}${args.userName?`&name=${args.userName}`:''}`                
        //         //const qry = `/api/get/userProfile1/${args.isFederated?`?is_federated=${args.isFederated}`:''}`                
        //         return qry
        //     },
        //     providesTags: (result, response, updated) => {
        //         return [{ type: 'Profile', id: result?.id??'' }]
        //     }
        // }),

        // getProfilesForPoint: builder.query<any[], void>({
        //     query: () => `/api/get/profilesforpoint/?radiusNumber=160934`,//refactorlat=${args.lat}&lng=${args.lng}&
        //     providesTags: (result) => {
        //         if (result) {
        //             return [//...result.map((row) => (                        { type: 'Profile' as const, id: row.id })), 
        //                 { type: 'ProfilePinData', id: 'LIST' }]
        //         }
        //         return [{ type: 'ProfilePinData' as const, id: 'LIST' }]
        //     }
        // }),
        getProfilesPinDataForDefaultPoint: builder.query<any[], void>({
            query: () => `/api/get/profilespindatafordefaultpoint/?radiusNumber=160934`,//refactorlat=${args.lat}&lng=${args.lng}&
            providesTags: (result) => {
                if (result) {
                    return [//...result.map((row) => (                        { type: 'Profile' as const, id: row.id })), 
                        { type: 'ProfilePinData', id: 'LIST' }]
                }
                return [{ type: 'ProfilePinData' as const, id: 'LIST' }]
            }
        }),
        getProfilesPinData: builder.query<any[], void >({
            query: (args: any) => `/api/get/profilespindata`,
            providesTags: (result) => {                
                return [{ type: 'ProfilePinData' as const, id: 'LIST' }]
            }
        }),
        getProfile: builder.query<any, number>({
            query: (id) => {
                const qry = `/api/get/profile/${id}`                
                return qry
            },
            providesTags: (result, response, id) => {
                return [{ type: 'ChatUserProfile', id: id }]
            }
        }),        
        UpdateProfile: builder.mutation<any, { id: number | null, profileDTO: any /*& Pick<any, | 'lat' | 'lng'>*/ }>({
            query: ({ id, profileDTO: profile2Update }) => ({
                url: `/api/update/userProfile/${id}`,
                method: 'PATCH',
                body: profile2Update,
            }),
            invalidatesTags: (result, response, updated) => [
               //{ type: 'Profile', id: updated.id ? updated.id : "not invalidating unauthorized"},
               //{ type: 'Profile', id: updated.id ? updated.id : "not invalidating unauthorized" },
              ]          
        }),
        
        UpdateProfileGroup: builder.mutation<any, { id: number | null, groupObject: any /*& Pick<any, | 'lat' | 'lng'>*/ }>({
            query: ({ id, groupObject }) => ({
                url: `/api/update/userProfileGroup/${id}`,
                method: 'PATCH',
                body: groupObject,
            }),
            invalidatesTags: (result, response, updated) => [
                { type: 'Profile', id: updated?.id??'' }
               //{ type: 'Profile', id: updated.id ? updated.id : "not invalidating unauthorized"},
               //{ type: 'Profile', id: updated.id ? updated.id : "not invalidating unauthorized" },
              ]          
        }),
        

        DeleteUserData: builder.mutation<void, number>({
            query: (id: number) => {
                console.log( 'Deleting Profile', id)
                return ({
                url: `/api/DeleteUserData/${id}`,
                method: 'DELETE',
            })
        },
            //invalidatesTags: (result, response, updated) => [{ type: 'Profile', id: updated }]
        }),
        UpdateUsername: builder.mutation<any, { id: number, username: string /*& Pick<any, | 'lat' | 'lng'>*/ }>({
            query: ({ id, username }) => ({
                url: `/api/update/username/${id}`,
                method: 'PATCH',
                body: {username: username},
            }),      
            invalidatesTags: ['Username'], // Invalidate the tag on mutation 
        }),
    }),
})


export const { useGetProfileQuery, useGetProfilesPinDataQuery, 
    //useGetUserProfileByNameQuery,
    useGetLoggedInUserProfileQuery, 
//    useGetLoggedInUserProfile1Query,
    //useGetProfilesForPointQuery,
    useGetProfilesPinDataForDefaultPointQuery,
    // useDeleteProfileMutation,
     useUpdateProfileMutation, useDeleteUserDataMutation, useUpdateUsernameMutation,
     useGetEmailConfirmationStatusQuery , useUpdateProfileGroupMutation} = profileApi


export function isFetchBaseQueryError(
    error: unknown
): error is FetchBaseQueryError {
    return typeof error === 'object' && error !== null && 'status' in error
}
/**
 * Type predicate to narrow an unknown error to an object with a string 'message' property
 */
export function isErrorWithMessage(
    error: unknown
): error is { message: string } {
    return (
        typeof error === 'object' &&
        error !== null &&
        'message' in error &&
        typeof (error as any).message === 'string'
    )
}
