import { Alert, Box, Chip, Link, SvgIcon, Tooltip, Typography } from "@mui/material";
import React, { useEffect } from "react"
import { GetPinByCategoryAndType, PinChip, pinIconStyle } from "../MessageProfile";
import { themePolygon } from "../../app/theme";
import { formatPhoneNumber } from "../../app/utils";
import PhoneIcon from '@mui/icons-material/Phone';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { LoadingButton } from '@mui/lab';
import { StartChat } from "./MMMarkers";
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { DirectChatUsers, useCreateOrJoinGroupChatMutation, useSetChatUserMutation } from '../../features/ChatSlice'
import { useAuthProfile } from "../../app/hooks"
import { useNavigate } from "react-router-dom";
import EmailIcon from '@mui/icons-material/Email';
import GroupIcon from '@mui/icons-material/Group';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import ScheduleIcon from '@mui/icons-material/Schedule';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import { template } from "lodash";
import { useUpdateProfileGroupMutation, useUpdateProfileMutation } from "../../features/profilesSlice";

export const InfoWindowDetails: React.FC<any> = ({ data, pinRightOf, children }) => {
  //const pinData = GetPinByCategoryAndType("Institution", data.type, { style: pinIconStyle })
  return (<>
    <Stack direction="column" gap={0} sx={{ maxHeight: '275px', maxWidth: '225px', p: 1, position: 'relative', top: 0 }}>
      {data.web_site
        ?
        <Typography variant="body2" sx={{ fontWeight: 'bold' }}><a target="_blank" rel="noopener noreferrer" href={data.web_site}>
          {data.name}
        </a></Typography>
        :
        <Typography variant="body2" sx={{ fontWeight: 'bold' }}>{data.name}</Typography>
      }
      <Stack gap={1} direction="row"> <PinChip category="Institution" type={data.type} size="small" />   {pinRightOf} </Stack>
      {data.address && <Typography display="flex" variant="caption" color="#656EFF"><RoomOutlinedIcon fontSize="small" color="primary" />{data.address}</Typography>}
      {data.phone && <Typography color="primary" display="flex" sx={{ gap: '2px' }} variant="caption"><PhoneIcon fontSize="small" color="primary" />{formatPhoneNumber(data.phone)}</Typography>}
      {data.email && <Typography display="flex" sx={{ gap: '2px' }} variant="caption" color="#656EFF"><EmailIcon fontSize="small" color="primary" /><Link href={`mailto:${data.email}`} underline="always" color="primary">{data.email}</Link></Typography>}
      {data.description && <Typography variant="body2">{data.description}</Typography>}
      <div>{children}</div>
    </Stack>
  </>);
}

export const CoeInfoWindowDetails: React.FC<any> = ({ data }) => {
  const navigate = useNavigate()
  const [SetChat, { isLoading: isLoadingSetChat, isSuccess: isSuccessSetChat, isError: isErrorSetChat, data: setChatData, originalArgs }] = useCreateOrJoinGroupChatMutation()
  const { authStatus, profile, messagingReady, isLoading: isAuthProfileLoading, isSuccess, isError, error, userAttributesStateStatus } = useAuthProfile()

  useEffect(() => {
    if (isSuccessSetChat && setChatData) {
      navigate("/Messages", { state: { chatAndUserData: setChatData } })//navigate("/Messages", { state: { messageTo: profile.iam_id, id: profile.id } })
    }
  }, [isSuccessSetChat, messagingReady, navigate, setChatData])

  if (isErrorSetChat)
    return <Alert severity="error">Oops... Error occurred while initiating a chat.  Please try again later.</Alert>
  return (<InfoWindowDetails data={data}>
    <>

      {data.mm_program_overview?.href && <Typography variant="body2"><Link target="_blank" rel="noopener noreferrer" href={data.mm_program_overview.href}>{data.mm_program_overview.text ?? "MM Program Overview"}</Link></Typography>}
      {data.meet_the_mm_team?.href && <Typography variant="body2"><Link target="_blank" rel="noopener noreferrer" href={data.meet_the_mm_team.href}>{data.meet_the_mm_team.text ?? "MM Specialists"}</Link></Typography>}
      {data.academic_affiliation?.href && <Typography variant="body2">Academic Affiliation:&nbsp;<Link target="_blank" rel="noopener noreferrer" href={data.academic_affiliation.href}>{data.academic_affiliation.text}</Link></Typography>}
      {data.comment?.href && <Typography variant="body2"><Link target="_blank" rel="noopener noreferrer" href={data.comment.href}>{data.comment.text ?? "Addl info"}</Link></Typography>}

      {data.clinical_trials?.href && <Typography variant="body2"><Link target="_blank" rel="noopener noreferrer" href={data.clinical_trials.href}>Clinical Trials(Phase I/II)</Link></Typography>}
      {data.myeloma_magizine?.href && <Typography variant="body2"><Link target="_blank" rel="noopener noreferrer" href={data.myeloma_magizine}>Myeloma Magizine</Link></Typography>}
      {data.research?.href && <Typography variant="body2"><Link target="_blank" rel="noopener noreferrer" href={data.research}>Research</Link></Typography>}
      {data.locations?.href && <Typography variant="body2"><Link target="_blank" rel="noopener noreferrer" href={data.locations}>Locations</Link></Typography>}
      {data.facebook?.href && <Typography variant="body2"><Link target="_blank" rel="noopener noreferrer" href={data.facebook}>Facebook</Link></Typography>}
      {data.twitter?.href && <Typography variant="body2"><Link target="_blank" rel="noopener noreferrer" href={data.twitter}>Twitter</Link></Typography>}
      {data.number_of_mm_specialists_simplified && <Typography variant="body2">Number of MM Specialists: {data.number_of_mm_specialists_simplified} </Typography>}
      <Divider sx={{ width: '100%', mb: 1, mt: 1 }}></Divider>
      <Stack direction="row">
        <LoadingButton sx={{ fontSize: '11px', backgroundColor: '#656EFF' }} id="resource_query_chat" loading={isLoadingSetChat}
          disableElevation variant="contained" startIcon={<PeopleAltIcon />} onClick={() => {
            const ids = [profile.id, 1]
            StartChat(ids, authStatus, () => navigate("/Messages"), () => SetChat({ loggedInId: profile.id, channelType: 'resource-query', channelName: data.name ?? '', ids: [profile.id, 1] }), true)
          }}>
          Join Group Chat
        </LoadingButton>
      </Stack>
    </>
  </InfoWindowDetails>);
}

export const HousingInforWindowDetails: React.FC<any> = ({ data }) => {
  return (<InfoWindowDetails data={data}>
    <>
      {data.description && <Typography variant="body2">Number of MM Specialists{data.description} </Typography>}
    </>
  </InfoWindowDetails>)

}

export const SupportGroupInfoWindowDetails: React.FC<any> = ({ data }) => {
  const navigate = useNavigate()
  const [SetChat, { isLoading: isLoadingSetChat, isSuccess: isSuccessSetChat, isError: isErrorSetChat, data: setChatData, originalArgs }] = useCreateOrJoinGroupChatMutation()
  const [SetDirectChat, { isLoading: isLoadingSetDirectChat, isSuccess: isSuccessSetDirectChat, isError: isErrorSetDirectChat, data: setDirectChatData }] = useSetChatUserMutation()
  const { authStatus, profile, messagingReady, isLoading: isAuthProfileLoading, isSuccess, isError, error, userAttributesStateStatus } = useAuthProfile()
  const [UpdateProfileGroup, { isLoading, isSuccess: isSuccessupdatedProfile, isError: isErrorupdatedProfile, error: errorupdatedProfile, data: updatedProfileData, reset }] = useUpdateProfileGroupMutation()

  useEffect(() => {
    if (isSuccessSetChat && setChatData) {
      navigate("/Messages", { state: { chatAndUserData: setChatData } })//navigate("/Messages", { state: { messageTo: profile.iam_id, id: profile.id } })
    }
  }, [isSuccessSetChat, messagingReady, navigate, setChatData])
  useEffect(() => {
    if (isSuccessSetDirectChat && setDirectChatData) {
      const groupObject = { [data.id]: { contacted: Date.now(), type: 'S' } }
      UpdateProfileGroup({ id: profile.id, groupObject: groupObject })
      navigate("/Messages", { state: { chatAndUserData: setDirectChatData } })//navigate("/Messages", { state: { messageTo: profile.iam_id, id: profile.id } })
    }
  }, [isSuccessSetDirectChat, messagingReady, navigate, setDirectChatData])

  return (<InfoWindowDetails data={data} pinRightOf={data.metadata?.leaderId ? <LoadingButton
    sx={{ backgroundColor: '#4CAF50', borderRadius: '20px', textTransform: 'none', fontWeight: 'normal' }}
    id="support_group_join"
    loading={isLoadingSetDirectChat}
    disableElevation
    variant="contained"
    size="small"
    onClick={() => {
      const ids = [profile.id, data.metadata.leaderId]
      const alreadyContacted = !!profile?.metadata?.groups?.[data.id]?.contacted
      //const groupObject = { [data.id]: { contacted: Date.now(), type: 'S' } }
      StartChat(ids, authStatus, () => navigate("/Messages"), (setChatArg: DirectChatUsers) => { //SetDirectChat(setChatArg) }
        SetDirectChat({
          ...setChatArg, ...!alreadyContacted?{sendInChat: true}:{}, ...alreadyContacted?{}:{sendEmail: { template: 'contact_support_group', templateData: { name: profile.name_public, supportGroupId: data.id, supportGroupName: data.name, subject: 'Registration', message: `Hello,\n Patient Connect user ${profile.name_public} would like to become a member of ${data.name}.` } }},
          ...!alreadyContacted? {messageChat: `Hi, I'd like to join ${data.name}`}:{}
        },
        )
      })//, false)
      //UpdateProfileGroup({ id: profile.id, groupObject: groupObject })
    }}>
    Contact
  </LoadingButton> : null}  >
    <>
      {data.metadata?.membership && <Tooltip title="Membership requirments" placement="left"><Typography display="flex" sx={{ gap: '2px' }} variant="body2" color="#656EFF"><GroupIcon fontSize="small" color="primary" />{data.metadata?.membership}</Typography></Tooltip>}
      {data.metadata?.meetingType && <Tooltip title="Meeting setting" placement="left"><Typography display="flex" sx={{ gap: '2px' }} variant="body2" color="#656EFF"><MeetingRoomIcon fontSize="small" color="primary" />{data.metadata?.meetingType}</Typography></Tooltip>}
      {data.metadata?.meetingSchedule && <Tooltip title="Meetings schedule" placement="left"><Typography display="flex" sx={{ gap: '2px' }} variant="body2" color="#656EFF"><ScheduleIcon fontSize="small" color="primary" />{data.metadata?.meetingSchedule}</Typography></Tooltip>}
      {data.metadata?.isRegistrationRequired && <Chip style={{ marginLeft: 'auto' }} size="small" label={"Registration Required"} color="success" sx={{ backgroundColor: 'rgba(0, 0, 255, 0.5)' }} />}

      {!!!data.metadata?.noGroupChat && <Divider sx={{ width: '100%', mb: 1, mt: 1 }}></Divider>}

      <Stack direction="column" gap={1} sx={{ alignItems: 'stretch' }}>

        {!!!data.metadata?.noGroupChat && <LoadingButton sx={{ fontSize: '11px', backgroundColor: '#656EFF' }} id="resource_query_chat" loading={isLoadingSetChat}
          disableElevation variant="contained" startIcon={<PeopleAltIcon />} onClick={() => {
            const ids = [profile.id, 1]
            StartChat(ids, authStatus, () => navigate("/Messages"), () => SetChat({ loggedInId: profile.id, channelType: 'resource-query', channelName: data.name ?? '', ids: [profile.id, 1] }), true)
          }}>
          Group Chat
        </LoadingButton>}

        {/*(data.metadata?.chatWithLeader && data.metadata?.leaderId) && <LoadingButton
          sx={{ fontSize: '11px', backgroundColor: '#4CAF50' }}
          id="chat_with_leader"
          loading={isLoadingSetChat && originalArgs?.channelType === 'leader-chat'}
          disableElevation
          variant="contained"
          startIcon={<SmsOutlinedIcon />}
          onClick={() => {
            const ids = [profile.id, data.metadata.leaderId]
            StartChat(ids, authStatus, () => navigate("/Messages"), (setChatArg: DirectChatUsers) => { SetDirectChat(setChatArg) })//, false)
          }}>
          Chat with Leader
        </LoadingButton>
        */}
      </Stack>
    </>
  </InfoWindowDetails>);
}

export const ClinicalTrialInfoWindowDetails: React.FC<any> = ({ data }) => {
  const navigate = useNavigate()
  //const [SetChat, { isLoading: isLoadingSetChat, isSuccess: isSuccessSetChat, isError: isErrorSetChat, data: setChatData, originalArgs }] = useCreateOrJoinGroupChatMutation()
  const [SetDirectChat, { isLoading: isLoadingSetDirectChat, isSuccess: isSuccessSetDirectChat, isError: isErrorSetDirectChat, data: setDirectChatData }] = useSetChatUserMutation()
  const { authStatus, profile, messagingReady, isLoading: isAuthProfileLoading, isSuccess, isError, error, userAttributesStateStatus } = useAuthProfile()

  // useEffect(() => {
  //   if (isSuccessSetChat && setChatData) {
  //     navigate("/Messages", { state: { chatAndUserData: setChatData } })//navigate("/Messages", { state: { messageTo: profile.iam_id, id: profile.id } })
  //   }
  // }, [isSuccessSetChat, messagingReady, navigate, setChatData])
  useEffect(() => {
    if (isSuccessSetDirectChat && setDirectChatData) {
      navigate("/Messages", { state: { chatAndUserData: setDirectChatData } })//navigate("/Messages", { state: { messageTo: profile.iam_id, id: profile.id } })
    }
  }, [isSuccessSetDirectChat, messagingReady, navigate, setDirectChatData])

  const queryParams = new URLSearchParams({
    clinicalTrial: data.nctId,
    contactId: data.metadata?.leaderId
  });

  //   {data.nctId && <Tooltip title="National Cancer Instite Trial ID" placement="left"><Typography display="flex" sx={{ gap: '2px' }} variant="body2" color="#656EFF">
  //   <Link sx={{ cursor: 'pointer' }} underline="always" onClick={() =>navigate(`/Resources?${queryParams.toString()}`)}
  //   >{data.nctId}</Link>
  // </Typography></Tooltip>}


  return (<InfoWindowDetails data={data}>
    <>

      <Divider sx={{ width: '100%', mb: 1, mt: 1 }}></Divider>

      <Stack direction="column" gap={1} sx={{ alignItems: 'stretch' }}>
        {data.metadata?.leaderId && <>
          <LoadingButton
            sx={{ fontSize: '11px', backgroundColor: '#656EFF', textTransform: 'none' }}
            id="chat_with_leader"
            loading={isLoadingSetDirectChat}
            disableElevation
            variant="contained"
            onClick={() => navigate(`/Resources?${queryParams.toString()}`)}       >
            Learn More / Contact
          </LoadingButton>
          <LoadingButton
            sx={{ fontSize: '11px', backgroundColor: '#656EFF' }}
            id="chat_with_leader"
            loading={isLoadingSetDirectChat}
            disableElevation
            variant="contained"
            startIcon={<SmsOutlinedIcon />}
            onClick={() => {
              const ids = [profile.id, data.metadata.leaderId]
              StartChat(ids, authStatus, () => navigate("/Messages"), (setChatArg: DirectChatUsers) => { SetDirectChat(setChatArg) })//, false)
            }}>
            Chat with Leader
          </LoadingButton>
        </>}
      </Stack>
    </>
  </InfoWindowDetails>);
}