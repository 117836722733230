import React, { useState, useEffect, useRef, ReactNode, ReactElement } from "react"
//import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
//import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
//import { createTheme, ThemeProvider } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
//import Button from '@mui/material/Button';
//import Stack from '@mui/material/Stack';
import { useAuthProfile } from "../../app/hooks"
//import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import { IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Tooltip from '@mui/material/Tooltip';
import { useLazyGetSearchResultsQuery, useGetFAQsQuery, useGetRecentSearchesQuery, useLazyGetSearchAutocompleteQuery } from '../../features/resourcesSlice'
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import Markdown from 'react-markdown'

import Tabs from '@mui/material/Tabs';
import Tab, { TabProps } from '@mui/material/Tab';
import { styled } from '@mui/material/styles';
import { Alert, Button, Box, Stack, Skeleton, ListItemButton, ListItem, ListItemText, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import List from '@mui/material/List';
//import ListItem from '@mui/material/ListItem';
//import ListItemButton from '@mui/material/ListItemButton';
//import ListItemIcon from '@mui/material/ListItemIcon';
//import ListItemText from '@mui/material/ListItemText';
import Link from '@mui/material/Link';
import { useSearchParams } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Snackbar from '@mui/material/Snackbar';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
//import Badge from '@mui/material/Badge';
//import PersonIcon from '@mui/icons-material/Person';
import { debounce } from '@mui/material/utils';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { StartChat } from "../markers/MMMarkers";
import { DirectChatUsers, useCreateOrJoinGroupChatMutation, useSetChatUserMutation } from '../../features/ChatSlice'
import { useNavigate, useLocation } from "react-router-dom";
import ArrowCircleRightRoundedIcon from '@mui/icons-material/ArrowCircleRightRounded';
import { MarkdownEmbeddedImageRenderer } from '../../app/blob-url-from-Base64'
import { Padding } from "@mui/icons-material";
import { cleanText } from "./ResourceLanding";
import { RootState } from '../../app/store'; // Import the RootState type
import { RenderQuestionMetaData } from "./RenderStudies";
import Loadash from 'lodash';
import { CircularProgressCentered } from "../../app/sharedcomponents";
import { useGetClinicalTrialsByIdQuery } from "../../features/clinicalTrials";

export const capitalizeFirstAlpha = (str: string) => {
  let result = '';
  let foundFirstAlpha = false; // Flag to check if we've found the first alphabetic character

  for (let i = 0; i < str.length; i++) {
    // Check if the current character is an alphabetic letter
    if (!foundFirstAlpha && /[a-zA-Z]/.test(str[i])) {
      result += str[i].toUpperCase(); // Capitalize it
      foundFirstAlpha = true; // Mark that we've found the first alphabet
    } else {
      result += str[i]; // Add the rest of the characters as is
    }
  }

  return result;
};
const ResourceTab: React.FC<TabProps> = (props) => (
  <Tab
    disableRipple
    sx={{
      textTransform: 'none',
      fontWeight: 'bold',
      borderRadius: 4,
      marginRight: 1,
      marginLeft: 1,
      color: '#777777',
      backgroundColor: '#FAFAFA',
      '&:hover': {
        color: '#40a9ff',
      },
      '&.Mui-selected': {
        color: 'white',
        backgroundColor: '#656EFF',
      },
    }}
    {...props}
  />
);

interface SearchText { cleanText: string, validateText: string }

// const renderAbove: (searchText: SearchText, category: string) => ReactNode = (searchText, category) => <>
// <Stack direction="row" alignItems="center" gap={1} sx={{ display: 'flex', flexShrink: 1 }}>
//   <Stack direction="column" gap={0} sx={{ display: 'flex', flexShrink: 1 }}>
//     {category !== "Paid Studies" && <ResourceChatButton searchText={searchText} />}
//   </Stack>
//   <CopyToClipboardButton text={searchText.cleanText} />
// </Stack>
// {category === "Paid Studies" && <><Box sx={{ width: '100%', marginTop: '15px' }}><RenderQuestionMetaData metaData={{ eligibility: eligibility, studyType: studyType, compensation: compensation, status: status }} question={cleanTextObject.cleanText} />
// </Box>
//   <Box sx={{ width: '100%', marginTop: '15px' }}><Divider></Divider></Box></>}




export const PerplexitySearchResultsWithNavigation: React.FC<{ setLoading: (isLoading: boolean) => void }> = ({ setLoading }) => {
  const { authStatus, profile: profileLoggedIn, isLoading: isLoadingpProfileLoggedIn,    // isLoading: isLoadingProfileLoggedIn, isError: isErrorProfileLoggedIn, error: errorProfileLoggedIn, userAttributesStateStatus 
  } = useAuthProfile()
  const [SetChat, { isLoading: isLoadingSetChat, isSuccess: isSuccessSetChat, isError: isErrorSetChat, data: setChatData, originalArgs }] = useSetChatUserMutation()
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  // Access the current location (which contains the query string)
  const location = useLocation();
  // Use URLSearchParams to parse the query string
  const queryParams = new URLSearchParams(location.search);
  const searchText = queryParams.get('search');
  const cleanTextObject = cleanText(searchText ?? '')
  const category = searchParams.get('category');
  const clinicalTrial = searchParams.get('clinicalTrial');
  const eligibility = searchParams.get('eligibility')
  const studyType = searchParams.get('studyType')
  const compensation = searchParams.get('compensation')
  const studyContactId = searchParams.get('contactId')
  const studyCtaUrl = searchParams.get('studyCtaUrl')
  const status = searchParams.get('status')

  const [showSnackBar, setShowSnackBar] = React.useState<{ show: boolean, severity: 'error' | 'info' | 'success' | 'warning', text: string }>({ show: false, severity: 'success', text: '' });

  const confimrationMessage = 'An email has been sent to an administrator. You will receive a follow-up soon'

  useEffect(() => {
    if (isSuccessSetChat) {
      if (!originalArgs?.messageEmail) {
        if (isSuccessSetChat) {
          navigate("/Messages", { state: { chatAndUserData: setChatData } })
        }
      } else {
        setShowSnackBar({ show: true, severity: 'info', text: confimrationMessage })
      }
    }
    if (isErrorSetChat) {
      setShowSnackBar({ show: true, severity: 'error', text: 'Oops... Sorry, we can not communicate your interest in this study at the moment, please try again later' })
    }

  }, [isSuccessSetChat, originalArgs, isErrorSetChat])//dispatch, navigate,data.id, 


  const onImInterestedClick = (searchText: SearchText) => {
    StartChat([profileLoggedIn.id, Number(studyContactId)], authStatus, () => navigate("/Messages"),
      (setChatArg: DirectChatUsers) => {
        //const chatMsg = capitalizeFirstAlpha(searchText.cleanText);//Loadash.startCase(searchText.cleanText)
        const msg = `I am interested in participating this study: ${capitalizeFirstAlpha(searchText.cleanText)}.`
        SetChat({
          ...setChatArg, sendInChat: true, sendEmail: true,
          subject: `A Patient Connect user is interested in your study: ${capitalizeFirstAlpha(searchText.cleanText)}`,
          messageEmail: msg,
          messageChat: msg
        },
        )
      })
  }



return (<>
  <Paper sx={{ width: '100%' }}>
    <Stack direction="column" alignItems={"flex-start"} gap={1} sx={{ p: 1 }}>

      <Button startIcon={<ArrowBackIcon />} onClick={() => { navigate(`/Resources${category ? `?category=${encodeURIComponent(category)}` : ''}`) }} >
        Back
      </Button>
      {clinicalTrial && <ClinicalTrialSearchResults nciId={clinicalTrial} setLoading={setLoading}
        renderAbove={(searchText) => <><Stack direction="row" alignItems="center" gap={1} sx={{ display: 'flex', flexShrink: 1 }}>
          <CopyToClipboardButton text={searchText.cleanText} /></Stack></>}
        renderBelow={(searchText) =>
          <Box sx={{ width: '100%', gap: '10px', flexWrap: 'wrap', alignContent: 'space-between', justifyContent: 'space-around', alignItems: "center", display: 'flex', flexDirection: 'row', flexShrink: 1, marginBottom: '20px' }}>
            <Button disabled={profileLoggedIn.id === Number(studyContactId) || status?.toLowerCase() === 'closed'} id="study_im_interested" disableElevation sx={{ alignSelf: 'center', textTransform: 'none', fontWeight: 'normal', borderRadius: 8, backgroundColor: '#656EFF' }} variant="contained"
              onClick={() => {
                //SetChat({id: profileLoggedIn.id, messageToId: profile.id})
                StartChat([profileLoggedIn.id, studyContactId], authStatus, () => navigate("/Messages"),
                  (setChatArg: DirectChatUsers) => {
                    SetChat(setChatArg)
                  })
              }} >
              <Box sx={{ marginTop: '1px', display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: '5px' }}>
                <SmsOutlinedIcon />
              </Box>
              Contact Study administrator
            </Button>
          </Box>}
      />}

      {searchText && <PerplexitySearchResults searchText={cleanTextObject} setLoading={setLoading}
        renderAbove={(searchText) => <>
          <Stack direction="row" alignItems="center" gap={1} sx={{ display: 'flex', flexShrink: 1 }}>
            <Stack direction="column" gap={0} sx={{ display: 'flex', flexShrink: 1 }}>
              {category !== "Paid Studies" && <ResourceChatButton searchText={searchText} />}
            </Stack>
            <CopyToClipboardButton text={searchText.cleanText} />
          </Stack>
          {category === "Paid Studies" && <><Box sx={{ width: '100%', marginTop: '15px' }}><RenderQuestionMetaData metaData={{ eligibility: eligibility, studyType: studyType, compensation: compensation, status: status }} question={cleanTextObject.cleanText} />
          </Box>
            <Box sx={{ width: '100%', marginTop: '15px' }}><Divider></Divider></Box></>}

        </>}
        renderBelow={category === "Paid Studies" ? (searchText) =>
          <Box sx={{ width: '100%', gap: '10px', flexWrap: 'wrap', alignContent: 'space-between', justifyContent: 'space-around', alignItems: "center", display: 'flex', flexDirection: 'row', flexShrink: 1, marginBottom: '20px' }}>
            <Button disabled={profileLoggedIn.id === Number(studyContactId) || status?.toLowerCase() === 'closed'} id="study_im_interested" disableElevation sx={{ alignSelf: 'center', textTransform: 'none', fontWeight: 'normal', borderRadius: 8, backgroundColor: '#656EFF' }} variant="contained"
              {...studyCtaUrl ? { component: "a", href: studyCtaUrl, target:"_blank", rel: "noopener noreferrer" } : { onClick: () => onImInterestedClick(searchText) }} >
              I'm interested
            </Button>

            <Button disabled={profileLoggedIn.id === Number(studyContactId) || status?.toLowerCase() === 'closed'} id="study_chat" disableElevation
              sx={{ alignSelf: 'center', textTransform: 'none', fontWeight: 'normal', color: '#656EFF', borderRadius: 8, borderColor: '#656EFF' }} variant="outlined"
              onClick={() => {
                //SetChat({id: profileLoggedIn.id, messageToId: profile.id})
                StartChat([profileLoggedIn.id, studyContactId], authStatus, () => navigate("/Messages"),
                  (setChatArg: DirectChatUsers) => {
                    SetChat(setChatArg)
                  })
              }} >
              <Box sx={{ marginTop: '1px', display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: '5px' }}>
                <SmsOutlinedIcon />
              </Box>
              Chat with administrator
            </Button>
          </Box> : undefined}
      />}
    </Stack>
    {(isLoadingpProfileLoggedIn || isLoadingSetChat) && <>       <CircularProgressCentered />
      {!isLoadingSetChat && <Skeleton animation="wave" sx={{ mt: '5px' }} variant="rounded" width={200} height={100} />}
    </>}

  </Paper>
  <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} autoHideDuration={6000} open={showSnackBar.show} onClose={() => setShowSnackBar({ ...showSnackBar, show: false })} >
    <Alert onClose={() => setShowSnackBar({ ...showSnackBar, show: false })} severity={showSnackBar.severity ?? "success"} variant="filled" sx={{ width: '100%' }}>
      {showSnackBar.text}
    </Alert>
  </Snackbar>
</>)

}



//<Stack direction="row" alignItems="center" gap={1} sx={{ display: 'flex', flexShrink: 1 }}>
//            <Stack direction="column" gap={0} sx={{ display: 'flex', flexShrink: 1 }}>
//              <ResourceChatButton searchText={searchText} />
//            </Stack>
//            <CopyToClipboardButton text={searchText.cleanText} />
//          </Stack>


export const PerplexitySearchResults: React.FC<{
  searchText: SearchText,
  //stat
  setLoading: (isLoading: boolean) => void, //must be wrapped in useEffect so not to violate React's rendering rules.
  renderAbove?: (searchText: SearchText) => React.ReactNode,
  renderBelow?: (searchText: SearchText) => React.ReactNode
}> = ({ searchText, setLoading, renderAbove, renderBelow }) => {
  const { authStatus, profile, messagingReady, isLoading: isAuthProfileLoading, isSuccess, isError, error, userAttributesStateStatus } = useAuthProfile()
  const isDataAboutToArrive = useSelector((state: RootState) => state.lazyResouceCompanion.isDataAboutToArrive);
  //const [SetChat, { isLoading: isLoadingSetChat, isSuccess: isSuccessSetChat, isError: isErrorSetChat, data: setChatData, originalArgs //isError: isErrorSetChat, error: errorSetChat, reset 
  //}] = useCreateOrJoinGroupChatMutation()
  //console.log(searchResult?.result )
  const [getSearch, { data: searchResult, isLoading: searchLoading, isFetching: isSearchFetching, isSuccess: isSearchSuccess, isError: searchError, isUninitialized: isSearchUninitialized }] = useLazyGetSearchResultsQuery()
  useEffect(() => {
    if (isSuccess && searchText) {
      getSearch({ query: searchText.cleanText, profile_id: profile.id }, true)// preferCacheValue: false // { forceRefetch: true })
    }
  }, [searchText, isSuccess, profile?.id])

  useEffect(() => {
    setLoading(searchLoading ?? false)//must be wrapped in useEffect so not to violate React's rendering rules.
  }, [searchLoading])

  return (<>
    {isDataAboutToArrive && isSearchSuccess &&
      <>
        {searchResult?.valid && renderAbove && renderAbove(searchText)}
        <MarkedDown searchText={searchText} searchResult={searchResult} />
        {searchResult?.valid && renderBelow && renderBelow(searchText)}
      </>
    }
    {!isDataAboutToArrive &&
      <Stack direction="column" gap={1}>
        <Skeleton animation="wave" variant="rectangular" width='150px' height={30} />
        <Skeleton animation="wave" variant="rectangular" width='200px' height={30} />
        <Skeleton animation="wave" variant="rectangular" width='100%' height={200} />
      </Stack>
    }
    {searchError && <Alert severity="error">Oops... Sorry, an error occurred answering your question.  Please try again later.</Alert>}

  </>)
}

export const ClinicalTrialSearchResults: React.FC<{
  nciId: string,
  //stat
  setLoading: (isLoading: boolean) => void, //must be wrapped in useEffect so not to violate React's rendering rules.
  renderAbove?: (searchText: SearchText) => React.ReactNode,
  renderBelow?: (searchText: SearchText) => React.ReactNode
}> = ({ nciId, setLoading, renderAbove, renderBelow }) => {

  const { data: clinicalTrialData, isLoading, isSuccess, isError, data, originalArgs, error } = useGetClinicalTrialsByIdQuery(nciId)
  const protocolSection = isSuccess ? clinicalTrialData?.protocolSection : {}
  return (<>
    {isLoading &&
      <Stack direction="column" gap={1}>
        <Skeleton animation="wave" variant="rectangular" width='150px' height={30} />
        <Skeleton animation="wave" variant="rectangular" width='200px' height={30} />
        <Skeleton animation="wave" variant="rectangular" width='100%' height={200} />
      </Stack>
    }

    {isSuccess && <>
      {renderAbove && renderAbove(cleanText(nciId))}
      <Typography variant="h6" sx={{ marginTop: 1, marginBottom: 1, justifyContent: 'center' }}><div>Clinical Trial: {nciId}</div></Typography>
      <Typography variant="h7" sx={{ marginTop: 0, marginBottom: 0 }}>{protocolSection.identificationModule?.officialTitle}</Typography>
      <Typography variant="body1" sx={{ marginTop: 1, marginBottom: 1 }}>{protocolSection.descriptionModule.briefSummary}</Typography>
      <Stack>
        <div><Stack gap={1} direction={'row'}><strong>Condition(s):</strong> {protocolSection.conditionsModule?.conditions?.join(", ")}</Stack></div>
        <div><Stack gap={1} direction={'row'}><strong>Study Type:</strong> {protocolSection?.designModule?.studyType}, {protocolSection?.designModule?.phases?.join(", ")}</Stack></div>
        <div><Stack gap={1} direction={'row'}><strong>Enrollment:</strong> {protocolSection.designModule?.enrollmentInfo?.count} ({protocolSection.designModule?.enrollmentInfo?.type.toLowerCase()}), {protocolSection?.statusModule?.overallStatus ?? ''}</Stack></div>

      </Stack>

      {protocolSection.descriptionModule?.detailedDescription && <Stack gap={{ xs: 0, sm: 1 }} direction={{ xs: 'column', sm: 'row' }}><strong><p>Detailed Description:</p></strong><Typography variant="body2" sx={{ marginBottom: 0 }}><MarkdownEmbeddedImageRenderer markdownContent={protocolSection.descriptionModule?.detailedDescription} /></Typography></Stack>}
      {protocolSection.eligibilityModule?.eligibilityCriteria && <Stack gap={{ xs: 0, sm: 1 }} direction={{ xs: 'column', sm: 'row' }}><Box sx={{ marginTop: 1.5 }}><strong>Eligibility:</strong> </Box><Typography variant="body2" ><MarkdownEmbeddedImageRenderer markdownContent={protocolSection.eligibilityModule?.eligibilityCriteria} /></Typography></Stack>}
      {renderBelow && renderBelow(cleanText(nciId))}

    </>}
    {isError && <Alert severity="error">Oops... Sorry, an error occurred answering your question.  Please try again later.</Alert>}

  </>)
}



export const ResourceChatButton: React.FC<{ searchText: SearchText }> = ({ searchText }) => {
  const { authStatus, profile, messagingReady, isLoading: isAuthProfileLoading, isSuccess, isError, error, userAttributesStateStatus } = useAuthProfile()
  const [SetChat, { isLoading: isLoadingSetChat, isSuccess: isSuccessSetChat, isError: isErrorSetChat, data: setChatData, originalArgs //isError: isErrorSetChat, error: errorSetChat, reset 
  }] = useCreateOrJoinGroupChatMutation()

  const navigate = useNavigate()
  useEffect(() => {
    if (isSuccessSetChat && setChatData) {//&& messagingReady) {
      // if( !messagingReady)
      //     dispatch(chatApi.util.invalidateTags(['Ready']))
      navigate("/Messages", { state: { chatAndUserData: setChatData } })//navigate("/Messages", { state: { messageTo: profile.iam_id, id: profile.id } })
    }
    // if (isErrorSetChat) {
    //     // Handle the error, and you can access originalArgs here           
    //   }
  }, [isSuccessSetChat, messagingReady, navigate, setChatData])
  return (<>
    <Stack direction="row">
      <LoadingButton sx={{ fontSize: '11px', backgroundColor: '#656EFF' }} id="resource_query_chat" loading={isLoadingSetChat}
        disableElevation variant="contained" startIcon={<PeopleAltIcon />} onClick={() => {
          const ids = [profile.id, 1]
          StartChat(ids, authStatus, () => navigate("/Messages"), () => SetChat({ loggedInId: profile.id, channelType: 'resource-query', channelName: searchText.cleanText ?? '', ids: [profile.id, 1] }), true)
        }}>
        Join discussion
      </LoadingButton>
    </Stack>

    {(isErrorSetChat) && <Alert severity="error">Oops... Error occurred while  initiating a chat.  Please try again later.</Alert>}

  </>)
}




export const MarkedDown: React.FC<{ searchText: SearchText, searchResult: any }> = ({ searchText, searchResult }) => {
  return (
    <>
      <MarkdownEmbeddedImageRenderer markdownContent={searchResult.result} encodedImages={searchResult.encodedImages} />
      {
        searchResult.sources?.length > 0 &&
        <>
          <Typography variant="h6">Additional Sources:</Typography>
          <ul>
            {searchResult.sources.map((row: any) => (
              <li key={row.link}><a href={row.link} target="_blank">{row.title}</a></li>
            ))}
          </ul>
          {searchResult.valid &&
            <Stack direction="row" alignItems="center" gap={1} sx={{ display: 'flex', flexShrink: 1 }}>
              <CopyToClipboardButton text={searchText.cleanText} />
            </Stack>
          }
        </>
      }
    </>)
}

// Clipboard component
export const CopyToClipboardButton: React.FC<{ text: string, getUrlString?: (text: string) => string }> = ({ text, getUrlString }) => {
  const [open, setOpen] = useState(false);

  //const baseURL = window.location.origin + window.location.pathname + "?search=" + encodeURIComponent(text);
  //const baseURL = window.location.origin + "/map/Resources" + "?search=" + encodeURIComponent(text);
  const location = useLocation();

  const handleClick = () => {
    // Get the current location object
    const currentUrl = getUrlString ? getUrlString(text) : `${window.location.href}`// `${location.pathname}${location.search}${location.hash}`;
    navigator.clipboard.writeText(currentUrl)//baseURL.toString());
    setOpen(true);
  };

  return (
    <>
      <Button startIcon={<ContentCopyIcon fontSize="small" />} onClick={handleClick} sx={{ color: '#777777', fontSize: '11px', backgroundColor: '#F4F4F4' }}>
        Share this resource
      </Button>
      <Snackbar
        message="Copied resource link to clipboard"
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={2000}
        onClose={() => setOpen(false)}
        open={open}
      />
    </>
  );
};



