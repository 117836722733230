import React, { useState } from "react";
import { useSelector, useDispatch, shallowEqual, } from "react-redux";
import { InfoWindowF, MarkerF } from '@react-google-maps/api';

//import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
//import Typography from '@mui/material/Typography';

// import patientIcon from '../../assets/patienticon.svg';
// import caregiverIcon from '../../assets/caregiver.svg';
// import medicIcon from '../../assets/medic.svg';
// import { ReactComponent as carecenterIcon } from '../../assets/carecentericon.svg';
// import { ReactComponent as housingIcon } from '../../assets/house.svg';
import carecenterIcon from '../../assets/carecentericon.png';
import housingIcon from '../../assets/house.png';
import futureStop from '../../assets/Future_stop.png'
import currentStop from '../../assets/Current_stop.png'
import pastStop from '../../assets/Past_stop.png'
import coeIcon from '../../assets/coe.png';
import clinicalTrialIcon from '../../assets/clinical_trial.png';
import supportIcon from '../../assets/support_group.png';
import walkIcon from '../../assets/walk.png';
import { activateMarker } from "../../features/activeMarkers";// activateCareCenter, activateWarrior /*} from '../features/activeMarkers'
//import { setPublicPinClicked, getProfileFromID, getAIProfileSummary } from "../../features/dataProcessingSlice"
//import { useEffect } from "react";
//import { GetUserTypePinSVG1 } from '../MessageProfile';
import { ClinicalTrialInfoWindowDetails, CoeInfoWindowDetails, HousingInforWindowDetails, SupportGroupInfoWindowDetails } from "./InfoWindowDetails";
import { useGeInstitutionsQuery } from "../../features/institutionsSlice";
import { CircularProgressCentered } from "../../app/sharedcomponents";
import { customIcon } from "./MMMarkers";
import { Description } from "@mui/icons-material";
import { Condition, condition, coonditionContext } from "../..";
import { useGetClinicalTrialsQuery } from "../../features/clinicalTrials";
import { skipToken } from "@reduxjs/toolkit/query";


export const InstitutionTypes = ['E', 'S', 'H', "TR", 'BSTP', 'WALK']
export interface MarkerTypeOption { type: string, status: string }
export const GetIntitutionTypePinPNG: (markerTypeVsMarkerTypeOption: string | MarkerTypeOption) => string = (markerTypeVsMarkerTypeOption) => {
  const { type: markerType, status: option } = typeof markerTypeVsMarkerTypeOption === 'object' ? markerTypeVsMarkerTypeOption : { type: markerTypeVsMarkerTypeOption, status: undefined }
  //   var option
  //  if( typeof markerTypeVsMarkerTypeOption === 'object') {
  //   markerType = markerTypeVsMarkerTypeOption.markerType
  //   option = 
  //  } else { 
  //   markerType = markerTypeVsMarkerTypeOption 
  //  }

  return markerType === 'H' ? housingIcon :
    markerType === 'E' ? coeIcon :
      markerType === 'C' ? carecenterIcon :
        markerType === 'S' ? supportIcon :
        markerType === 'TR' ? clinicalTrialIcon:
        markerType === 'WALK' ? walkIcon:
          markerType === 'BSTP' ? (option === 'C' ? currentStop : option === 'F' ? futureStop : option === 'P' ? pastStop : currentStop ) :
            carecenterIcon
}

export const ShowMarkerType: (pinType: string, institutionTypesState: any, unknownIsGood: boolean) => boolean = (pinType, institutionTypesState, unknownIsGood) => {
 
  const ret = typeof institutionTypesState === 'boolean' ? institutionTypesState : (pinType in institutionTypesState ? institutionTypesState[pinType] : unknownIsGood)
  if( pinType === 'TR'){
    console.log( 'TR', ret)
    return ret
  }
  return ret
}
export const InstitutionMarkers: React.FC<any> = ({ zoom }) => {
  const institutionTypesState = useSelector((state: any) => state.filter.showCareCenters, shallowEqual)
  const unknownIsGood = useSelector((state: any) => state.filter.unknownIsGood)
  const { data: institutions, isLoading, isSuccess, isError, error, } = useGeInstitutionsQuery()
  const {condition} = React.useContext<Condition>(coonditionContext);
  
  const arg = condition === 'Bladder Cancer' ? {condition: condition } : skipToken
  const { data: clinicalTrials, isLoading:clinicalTrialsIsLoading, isSuccess:clinicalTrialsIsSuccess, isError:clinicalTrialsIsError, error:clinicalTrialsError, } = useGetClinicalTrialsQuery(arg)

  if (isLoading)
    return (<CircularProgressCentered />)
  if (isError) {
    const errMsg = 'status' in error ? `Error: ${error.status}` : 'error';
    return <div>{errMsg}</div>;
  }
  const showAllTypes = typeof institutionTypesState === 'boolean' ? institutionTypesState : undefined
  if (!showAllTypes && showAllTypes !== undefined)
    return null

  //const pinType = row.data.type
  //const showMarkerType = typeof institutionTypesState === 'boolean' ? institutionTypesState : (pinType in institutionTypesState ? institutionTypesState[pinType] : unknownIsGood)

  return (<>
    {institutions?.map(
      (row: any) => (ShowMarkerType(row.type, institutionTypesState, unknownIsGood) && <InstitutionMarker zoom={zoom} id={-row.id} data={row} key={-row.id} />)
    )
    }
    {(clinicalTrialsIsSuccess && ShowMarkerType('TR', institutionTypesState, unknownIsGood)) && clinicalTrials?.map(
      (row: any) => (<InstitutionMarker zoom={zoom} id={row.id} data={row} key={row.id} />)
    )
    }
  </>
  )
}


export const InstitutionMarker: React.FC<any> = ({ id, data, zoom }) => {//{ markerType, lat, lng, name, isInfoWindowOpen = false, animation = null, description = undefined, address = undefined, phone = undefined, href = undefined, id })  =>{
  const [infoWindowOpen, setInfoWindowOpen] = useState(false);
  const isMarkerActive = useSelector(
    (state: any) => {
      if (state.activeMarkers?.id === id) {
        return true
      }
      return false
    }

  );

  const dispatch = useDispatch()
  const markerType = data.type

  const institutionTypePinPNG = GetIntitutionTypePinPNG(markerType)//markerType === 'H' ? housingIcon : markerType === 'E' ? coeIcon : markerType === 'carecenter' ? carecenterIcon : carecenterIcon

  function handleMouseOver() {
    if (isMarkerActive) {//activeMarkers.disableHover) {
      return;
    }
    setInfoWindowOpen(true);
    //activateCallBack(id)
  };

  function handleMouseOut() {
    if (isMarkerActive) {//activeMarkers.disableHover) {
      return;
    }
    setInfoWindowOpen(false);
  };

  function handleWindowClose() {
    setInfoWindowOpen(false);
    dispatch(activateMarker({ id: null, disableHover: false }))
  };

  function onLoad(marker: any) {
    window.google.maps.event.addListener(marker, "click", function (event: any) {
      dispatch(activateMarker({ id: id, disableHover: true }))
      setInfoWindowOpen(false);
    });
  }

  return (
    <MarkerF
      key={`marker-${data.id}-${zoom}`}
      onLoad={onLoad}//onClick={() => handleMarkerClick()}
      position={{ lat: parseFloat(data.lat), lng: parseFloat(data.lng) }}
      icon={customIcon(institutionTypePinPNG as unknown as string, zoom)}
      onMouseOver={() => handleMouseOver()}
      onMouseOut={() => handleMouseOut()}
    >
      {
        (isMarkerActive || infoWindowOpen) && (
          <InfoWindowF onCloseClick={() => handleWindowClose()}
            position={{ lat: parseFloat(data.lat), lng: parseFloat(data.lng) }}
            options={{
              disableAutoPan: true,
            }}
          >
            <Box>
              {(markerType === 'E') && <CoeInfoWindowDetails data={data} />}
              {(markerType === 'H') && <HousingInforWindowDetails data={data} />}
              {(markerType === 'C') && <CoeInfoWindowDetails data={data} />}
              {(markerType === 'S') && <SupportGroupInfoWindowDetails data={data} />}
              {(markerType === 'WALK') && <SupportGroupInfoWindowDetails data={data} />}
              {(markerType === 'TR') && <ClinicalTrialInfoWindowDetails data={data} />}
              
              {((typeof markerType === 'string' && markerType === 'BSTP') || (typeof markerType === 'object' && markerType.type === 'BSTP') ) && <SupportGroupInfoWindowDetails data={{...data, description: `${data.description} of a 3,100 mile eBike ride across the USA to raise money for multiple myeloma research, financial assistance, and awareness.`}} />}
            </Box>
          </InfoWindowF>
        )}
    </MarkerF>
  );

}




