import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch, shallowEqual, } from "react-redux";
import { InfoWindowF, MarkerF } from '@react-google-maps/api';

import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// import carecenterIcon from '../../assets/carecentericon.svg';
// import housingIcon from '../../assets/house.svg';
// import coeIcon from '../../assets/coe.svg';

import { activateMarker } from "../../features/activeMarkers";// activateCareCenter, activateWarrior /*} from '../features/activeMarkers'
import { setPublicPinClicked/*, getProfileFromID, getAIProfileSummary */ } from "../../features/dataProcessingSlice"
import { PinChip } from '../MessageProfile';
import { AppDispatch } from "../../app/store";
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import { useGetProfileQuery, useGetProfilesPinDataForDefaultPointQuery/*, useGetProfilesPinDataQuery*/ } from "../../features/profilesSlice";
import { CircularProgressCentered } from "../../app/sharedcomponents";
import { Alert, Skeleton } from "@mui/material";
//import { useAuthenticator } from "@aws-amplify/ui-react";
import { ShowMarkerType } from "./InstitutionMarkers";
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import { useNavigate } from "react-router-dom";
import { chatApi, useSetChatUserMutation, DirectChatUsers } from "../../features/ChatSlice";
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { useAuthProfile } from "../../app/hooks";
import { IsProdChatUserInTest, prodChatUserInTestMessage } from "../messaging/ChatMain";
import { SendbirdChatButtonProps } from "../messaging/SendbirdChatButton";
import { showDirectChat } from "../../features/dialogSlice";
import { Condition, baseName, coonditionContext } from "../..";
//const MMWarriorSVG2 = require(`../../assets/patienticon${baseName??''}.png`);

import { loadImage, loadImageSync } from "../../app/utils";
//import MMWarriorPNG from '../../assets/patienticon.png';
import CaregiverPNG from '../../assets/caregiver.png';
import MedicPNG from '../../assets/medic.png';
import OtherPNG from '../../assets/other.png';

export const GetUserTypePinPNG = (name: string) => {
  switch (name) {
    case "P":
      //return MMWarriorPNG
      return loadImageSync(`patienticon${baseName}.png`)      
    case "C":
      return CaregiverPNG
    case "M":
      return MedicPNG
    case "O":
      return OtherPNG
    default:
      return null
  }
}

export const customIcon: (url: string, zoom: number) => { url: string, scaledSize?: google.maps.Size } = (url, zoom) => {
  const markerSize = 58
  let scaledMarkerSize
  if (zoom > 10 ) {
    scaledMarkerSize = markerSize
  } else {
    scaledMarkerSize = Math.round(markerSize * (zoom / 10))
  }
  return {
    url: url,//userTypePinSVG as unknown as string,
    scaledSize: new window.google.maps.Size(scaledMarkerSize,  scaledMarkerSize),
  }
};

export const MMMarkerNotMemoized: React.FC<{ data: any, zoom: number }> = ({ data, zoom }) => {
  const { baseName } = React.useContext<Condition>(coonditionContext);


  const markerRef = useRef<any>(null);
  const dispatch = useDispatch<AppDispatch>()

  const [infoWindowOpen, setInfoWindowOpen] = useState(false);

  const isMarkerActive = useSelector(
    (state: any) => {
      if (state.activeMarkers?.id === data.id) {//refactorif (state.activeMarkers?.id === data.iam_id) {
        return true
      }
      return false
    }
  );

  const markerType = data.type

  function onLoad(marker: any) {
    markerRef.current = marker
    window.google.maps.event.addListener(marker, "click", function (event: any) {

      //setInfoWindowOpen(true);
      if (markerType === "P" || markerType === "C" || markerType === "M" || markerType === "O") {
        dispatch(setPublicPinClicked(data.id))//refactor dispatch(setPublicPinClicked(data.iam_id))
        //dispatch(getProfileFromID(data.id))//refactor dispatch(getProfileFromID(data.iam_id))
        //AISummary component  calls dispatch(getAIProfileSummary(data.id))
      } else {
        dispatch(setPublicPinClicked(false))
      }
      dispatch(activateMarker({ id: data.id, disableHover: true }))//dispatch(activateMarker({ id: data.iam_id, disableHover: true }))
      setInfoWindowOpen(false);
    });
  }

  function handleMouseOver() {
    if (isMarkerActive) {//if (activeMarkers.disableHover) {
      return;
    }
    setInfoWindowOpen(true);
    //activateCallBack(id)
  };

  function handleMouseOut() {
    if (isMarkerActive) {//if (activeMarkers.disableHover) {
      return;
    }
    setInfoWindowOpen(false);
  };


  function handleWindowClose() {
    setInfoWindowOpen(false);
    dispatch(activateMarker({ id: null, disableHover: false }))
    dispatch(setPublicPinClicked(false))
  };


  let userTypePinPNG = GetUserTypePinPNG(markerType)
  //let test =  loadImageSync(`coe.png`)
  if (userTypePinPNG)
    return (
      <MarkerF
        key={`marker-${data.id}-${zoom}`}
        onLoad={onLoad}
        position={{ lat: parseFloat(data.lat), lng: parseFloat(data.lng) }}
        icon={customIcon(userTypePinPNG as unknown as string, zoom)}
        onMouseOver={() => handleMouseOver()}
        onMouseOut={() => handleMouseOut()}
      //onClick={ handleMarkerClick}
      >
        {
          (infoWindowOpen || isMarkerActive) && (
            <InfoWindowF
              position={{ lat: parseFloat(data.lat), lng: parseFloat(data.lng) }}
              options={{
                disableAutoPan: true,
              }}
              onCloseClick={() => handleWindowClose()}>
              <MMMarkerInfoWindowDetails data={data} />
            </InfoWindowF>
          )}
      </MarkerF>
    );

  return null

}
export const StartChat: (idsOrderd: number[], authStatus: string, navigate: () => void, setChatFunc: (directChatUsers: DirectChatUsers) => void, skipUsersInTestCheck?: boolean) => void = (idsOrderd, authStatus, navigate, setChatFunc, skipUsersInTestCheck = false) => {
  if (authStatus !== 'authenticated') {
    navigate()
    return
  }
  const setChatArg: DirectChatUsers = { id: idsOrderd[0], messageToId: idsOrderd[1] }
  if (skipUsersInTestCheck || !IsProdChatUserInTest(idsOrderd[0], idsOrderd[1])) {
    setChatFunc(setChatArg)
  } else {
    alert(prodChatUserInTestMessage);
  }
  //navigate("/Messages", {state: {messageTo: data.iam_id, id: data.id } })
}

export const MMMarkerInfoWindowDetails: React.FC<{ data: any, children?: React.ReactNode }> = ({ data, children }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const { data: profile, isLoading, isFetching, isSuccess, isError, error, } = useGetProfileQuery(data.id)
  const [SetChat, { isLoading: isLoadingSetChat, isSuccess: isSuccessSetChat, isError: isErrorSetChat, data: setChatData,
    //isError: isErrorSetChat, error: errorSetChat, reset 
  }] = useSetChatUserMutation()
  const { authStatus, profile: profileLoggedIn, isLoading: isLoadingpProfileLoggedIn,    // isLoading: isLoadingProfileLoggedIn, isError: isErrorProfileLoggedIn, error: errorProfileLoggedIn, userAttributesStateStatus 
  } = useAuthProfile()

  useEffect(() => {
    if (isSuccessSetChat) {
      console.log("setChatData about to navigate to /Messages", setChatData)
      navigate("/Messages", { state: { chatAndUserData: setChatData } })
    }

  }, [isSuccessSetChat, setChatData, navigate])//dispatch, navigate,data.id, 

  if (isError) {
    const errMsg = 'status' in error ? `Error: ${error.status}` : 'error';
    return <div>{errMsg}</div>;
  }

  if (isErrorSetChat)
    return <Alert severity="error">Oops... Error occurred while initiating a chat.  Please try again later.</Alert>
  const showSearchTerm = profile?.formattedsearchterm ? !!profile.formattedsearchterm.trim().length: false
  return (<>
    <Box sx={{ maxHeight: '250px', maxWidth: '225px' }}>
      <Stack direction="row" alignItems={'center'} gap={1}>
        <Typography sx={{  mb: '5px', fontWeight: 'bold' }} variant="subtitle1">
          {data.name_public/*profile.name_public*/}
        </Typography>
        <IconButton id="pin_message_button" size="large" onClick={() => {
          //SetChat({id: profileLoggedIn.id, messageToId: profile.id})
          StartChat([profileLoggedIn.id, profile.id], authStatus, () => navigate("/Messages"),
            (setChatArg: DirectChatUsers) => {
              SetChat(setChatArg)
            })
        }}
          sx={{ color: "white", backgroundColor: '#656EFF' }}>
          <SmsOutlinedIcon />
        </IconButton>
      </Stack>

      <PinChip category="User" type={data.type} size="small" />
      {showSearchTerm && <Box sx={{ mt: '5px', width: '100%', display: 'flex', alignItems: 'flex-end' }}>
        <RoomOutlinedIcon fontSize="small" color="primary" />
        {isLoading ? <Skeleton animation="wave" sx={{ mt: '10px', flexGrow: 10 }} variant="text" /> : <Typography variant="body2" color="#656EFF">{profile.formattedsearchterm}</Typography>}
      </Box>}
      {(isLoading || isFetching || isLoadingpProfileLoggedIn || isLoadingSetChat) && <>       <CircularProgressCentered />
        {!isLoadingSetChat && <Skeleton animation="wave" sx={{ mt: '5px' }} variant="rounded" width={200} height={100} />}
      </>}
      {isSuccess && <>
        <Typography sx={{ mt: '5px' }} variant="body2">{profile.comment}</Typography>
        {data.web_site && <Typography sx={{ mt: '5px' }} variant="body2">Website:&nbsp;<Link target="_blank" rel="noopener noreferrer" href={data.web_site}>{data.web_site}</Link></Typography>}
        <div>{children}</div></>
      }

    </Box >
  </>);
  //return null
}


export const Warriors: React.FC<{ zoom: number }> = ({ zoom }) => {
  const userTypesState = useSelector((state: any) => state.filter.showWarriors, shallowEqual)
  const unknownIsGood = useSelector((state: any) => state.filter.unknownIsGood)
  const [userTypePinSVG, setUserTypePinSVG] = useState<string | null>(null);
  const { condition, baseName } = React.useContext<Condition>(coonditionContext);
  useEffect(() => {
    const fetchImage = async () => {
    // if (baseName) {
        //const path = await loadImage(`../../assets/patienticon${baseName}.png`);
        const path = await loadImage(`patienticon${baseName}.png`);
        setUserTypePinSVG(path)
     // }
    };
    fetchImage();
  }, [baseName]); // Re-run effect if baseName changes



  //const {data: warriors, isLoading, isSuccess, isError, error, } = useGetProfilesPinDataQuery();
  const { data: warriors, isLoading, isSuccess, isError, error, } = useGetProfilesPinDataForDefaultPointQuery();

  const { authStatus, profile: loggedInUserProfile, isLoading: isLoadingLoggedInUserProfile, isSuccess: isSuccessLoggedInUserProfile, isError: isErrorLoggedInUserProfile, error: errorLoggedInUserProfile /*, userAttributesStateStatus*/ } = useAuthProfile()

  if (isLoading || isLoadingLoggedInUserProfile || authStatus === 'configuring')
    return (<CircularProgressCentered />)

  const loggedInUserId = (isSuccessLoggedInUserProfile && authStatus === 'authenticated') ? loggedInUserProfile.id : null

  if (isError) {
    const errMsg = 'status' in error ? `Error: ${error.status}` : 'error';
    return <div>{errMsg}</div>;
  }
  if (isErrorLoggedInUserProfile) {
    const errMsg = 'status' in errorLoggedInUserProfile ? `Error: ${errorLoggedInUserProfile.status}` : 'error';
    return <div>{errMsg}</div>;
  }
  const skipAllTypes = typeof userTypesState === 'boolean' ? !userTypesState : false
  if (skipAllTypes)
    return null
  //user marker drawn separatly using <UserMarker>  
  //const pinType = row.type
  // const showMarkerType = typeof userTypesState === 'boolean' ? userTypesState : (pinType in userTypesState ? userTypesState[pinType] : unknownIsGood)
  // if (!showMarkerType || iamId === row.iam_id)
  //   return (null)
  if (isSuccess && isSuccessLoggedInUserProfile && userTypePinSVG)
    return (<>
      {warriors?.map(
        (row: any) => {
          const isLoggedInPing = loggedInUserId === row.id//refactor  const isLoggedInPing = iamId === row.iam_id
          //console.log(iamId, row.iam_id, iamId !== row.iam_id )
          return ((ShowMarkerType(row.type, userTypesState, unknownIsGood) && !isLoggedInPing) && <MMMarker zoom={zoom} data={row} key={row.id} />)
        })
      }
    </>
    )
  return null
}


export const MMMarker = React.memo(MMMarkerNotMemoized);

