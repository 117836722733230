export interface BikeStop  {  web_site: string, name: string, type:{ type: string, status: string}, id: number,lat:number, lng:number, address: string, description: string}
//types P
const bikeStops: BikeStop[] = [
    {"web_site":"https://wheelingforhealing.com/","name":"Wheeling for Healing","type":{"type":"BSTP","status":"P"},"id":-1000001,"lat":32.715738,"lng":-117.1610838,"address":"San Diego, CA","description":"Stage 1"},
    {"web_site":"https://wheelingforhealing.com/","name":"Wheeling for Healing","type":{"type":"BSTP","status":"P"},"id":-1000002,"lat":32.792,"lng":-115.5630514,"address":"El Centro, CA","description":"Stage 2"},
    {"web_site":"https://wheelingforhealing.com/","name":"Wheeling for Healing","type":{"type":"BSTP","status":"P"},"id":-1000003,"lat":33.6177725,"lng":-114.5882607,"address":"Blythe, CA","description":"Stage 3"},
    {"web_site":"https://wheelingforhealing.com/","name":"Wheeling for Healing","type":{"type":"BSTP","status":"P"},"id":-1000004,"lat":33.9697664,"lng":-112.7301882,"address":"Wickenburg, AZ","description":"Stage 4"},
    {"web_site":"https://wheelingforhealing.com/","name":"Wheeling for Healing","type":{"type":"BSTP","status":"P"},"id":-1000005,"lat":33.3942223,"lng":-110.7864984,"address":"Globe, AZ","description":"Stage 5"},
    {"web_site":"https://wheelingforhealing.com/","name":"Wheeling for Healing","type":{"type":"BSTP","status":"P"},"id":-1000006,"lat":32.8339546,"lng":-109.70758,"address":"Safford, AZ","description":"Stage 6"},
    {"web_site":"https://wheelingforhealing.com/","name":"Wheeling for Healing","type":{"type":"BSTP","status":"P"},"id":-1000007,"lat":32.770075,"lng":-108.280326,"address":"Silver City, NM","description":"Stage 7"},
    {"web_site":"https://wheelingforhealing.com/","name":"Wheeling for Healing","type":{"type":"BSTP","status":"P"},"id":-1000008,"lat":32.3199396,"lng":-106.7636538,"address":"Las Cruces, NM","description":"Stage 8"},
    {"web_site":"https://wheelingforhealing.com/","name":"Wheeling for Healing","type":{"type":"BSTP","status":"P"},"id":-1000009,"lat":31.7618778,"lng":-106.4850217,"address":"El Paso, TX","description":"Stage 9"},
    {"web_site":"https://wheelingforhealing.com/","name":"Wheeling for Healing","type":{"type":"BSTP","status":"P"},"id":-1000010,"lat":30.5882111,"lng":-103.8946253,"address":"Fort Davis, TX","description":"Stage 10"},
    {"web_site":"https://wheelingforhealing.com/","name":"Wheeling for Healing","type":{"type":"BSTP","status":"P"},"id":-1000011,"lat":29.3708857,"lng":-100.8958674,"address":"Del Rio, TX","description":"Stage 11"},
    {"web_site":"https://wheelingforhealing.com/","name":"Wheeling for Healing","type":{"type":"BSTP","status":"P"},"id":-1000012,"lat":30.267153,"lng":-97.7430608,"address":"Austin, TX","description":"Stage 12"},
    {"web_site":"https://wheelingforhealing.com/","name":"Wheeling for Healing","type":{"type":"BSTP","status":"P"},"id":-1000013,"lat":34.7444618,"lng":-92.2880157,"address":"Little Rock, AR","description":"Stage 13"},
    {"web_site":"https://wheelingforhealing.com/","name":"Wheeling for Healing","type":{"type":"BSTP","status":"P"},"id":-1000014,"lat":30.4514677,"lng":-91.1871465999999,"address":"Baton Rouge, LA","description":"Stage 14"},
    {"web_site":"https://wheelingforhealing.com/","name":"Wheeling for Healing","type":{"type":"BSTP","status":"P"},"id":-1000015,"lat":30.6953657,"lng":-88.0398912,"address":"Mobile, AL","description":"Stage 15"},
    {"web_site":"https://wheelingforhealing.com/","name":"Wheeling for Healing","type":{"type":"BSTP","status":"P"},"id":-1000016,"lat":30.4213089999999,"lng":-87.2169149,"address":"Pensacola, FL","description":"Stage 16"},
    {"web_site":"https://wheelingforhealing.com/","name":"Wheeling for Healing","type":{"type":"BSTP","status":"P"},"id":-1000017,"lat":30.4381828,"lng":-84.2806234999999,"address":"Tallahassee, FL","description":"Stage 17"},
    {"web_site":"https://wheelingforhealing.com/","name":"Wheeling for Healing","type":{"type":"BSTP","status":"P"},"id":-1000018,"lat":29.6519563,"lng":-82.324998,"address":"Gainesville, FL","description":"Stage 18"},
    {"web_site":"https://wheelingforhealing.com/","name":"Wheeling for Healing","type":{"type":"BSTP","status":"P"},"id":-1000019,"lat":30.3321838,"lng":-81.6556509999999,"address":"Jacksonville, FL","description":"Stage 19"},
]
export default bikeStops;
