// apiSlice.ts
import {  createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'


export const clinicalTrialssApi = createApi({
    reducerPath: 'clinicalTrialsApi',
    baseQuery: fetchBaseQuery(),
    tagTypes: ['CLINICAL_TRIAL', 'CLINICAL_TRIAL_LIST'],
    endpoints: (builder) => ({
        //gest profile  by iam_is inserts if no matching profile found        
        getClinicalTrials: builder.query<any[], {condition: string, asofDate?: Date}>({
            query: ({condition, asofDate}) => 
            ({
                url: `/api/clinicaltrials/search/?${asofDate?`asofDate=${asofDate.toISOString().split('T')[0]}`:''}&condition=${condition}`            ,
                method: 'POST',
                //body: { id: id, story: story, doAnalytics: doAnalytics ? true : false }
            }),
            providesTags: (result, response, updated) => {
                return ['CLINICAL_TRIAL_LIST']
            }
        }),
        getClinicalTrialsById: builder.query<any,  string>({
            query: (nciId) => {
                const qry = `/api/clinicaltrials/?nciId=${nciId}`                
                return qry
            },
            providesTags: (result, response, updated) => {
                 return [{ type: 'CLINICAL_TRIAL', id: result?.nciId}]
             }
        }),
    }),
})


export const { useGetClinicalTrialsQuery, useGetClinicalTrialsByIdQuery } = clinicalTrialssApi


