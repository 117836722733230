import React, { useState, useRef } from "react";
import { createContext, ReactElement, useContext } from "react";
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Box, Button, Typography, Chip, Alert, Collapse, AlertTitle, IconButton } from '@mui/material';
import { NavBar1 } from './components/navbar/NavBar1';
import { Map } from './components/map/Map';
import { Disclaimer, DataDeletionInstructions } from './components/Disclaimer';
import { LeftDrawer } from './components/LeftDrawer';
//import { Resources } from './components/resources/LinkGrid';
import Grid from '@mui/material/Grid';
import { MapBars } from './components/map/MapBars'
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import { CircularProgress } from "@mui/material";
import { setSearch } from './features/searchSlice'
import { getDisclaimerCookie, loadImage, setDisclaimerCookie } from './app/utils'
import { getUserAttributes } from './features/userAttributesSlice'
import { ProfileWizard } from './components/profile/ProfileWizard'

import { AddPinChip } from './components/profile/AddPinChip'
import { showDisclaimer, showProfile, showSurvey, showDeleteAccount, showDataDeletionInstructions, showBanner } from "./features/dialogSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthProfile } from "./app/hooks"
import { CenterMapHome } from "./components/map/CenterMapHome";
import { ChatMain } from "./components/messaging/ChatMain"
import { activateMarker } from "./features/activeMarkers"
import { signInWithRedirect/*, deleteUser*/ } from 'aws-amplify/auth';
import { showCareCenters, showWarriors } from './features/filterSlice';
import { setPublicPinClicked } from './features/dataProcessingSlice';
import { printError } from './app/utils';
import { chatColors, SendbirdChatButton } from "./components/messaging/SendbirdChatButton"
//import {  useGetMessagingQuery } from './features/ChatSlice';
import SendbirdProvider from "@sendbird/uikit-react/SendbirdProvider";
import { CircularProgressCentered } from "./app/sharedcomponents";
import { BottomSheet, BottomSheetRef } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { useMediaQuery, useTheme } from "@mui/material";
import { AddBox } from "@mui/icons-material";
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import { ConfirmDeleteProfile } from "./components/iam/DeleteProfile";
import { QueryStatus } from "@reduxjs/toolkit/query";
import { RootState } from './app/store';
import { Condition, coonditionContext } from ".";
import { createSelector } from "@reduxjs/toolkit";
import { ScienceOutlined } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';

export const bannerCTAUrl = 'https://docs.google.com/forms/d/1QfTuPpdH0fsR5-dxZnZhnQHm6hG3QBkvLNOdGDj9MNY/viewform?edit_requested=true'
const bannerUrl = "/Resources?search=%24500+Remote+Research+Opportunity&category=Paid+Studies&status=Open&eligibility=Patients+diagnosed+with+bladder+cancer+Jan+1%2C+2016%2C+onward&studyType=Online+interview&compensation=%24500.+Receive+a+%2410+bonus+upon+sign+up+and+qualification.&contactId=44515&studyCtaUrl=https%3A%2F%2Fdocs.google.com%2Fforms%2Fd%2F1QfTuPpdH0fsR5-dxZnZhnQHm6hG3QBkvLNOdGDj9MNY%2Fviewform%3Fedit_requested%3Dtrue"
const bannerTitle = "$500 Remote Research Opportunity"
const bannerBBody = "Despite researchers using electronic health records for their studies, they often don’t have the full picture of a patient’s journey — from diagnosis to treatment to long-term outcomes. This study will test whether a patient-driven approach of accessing health data will provide a more complete view of the patient journey than the traditional electronic health record approach. A more complete view of the patient journey will help researchers better understand your condition and improve treatments for others in the future. Your involvement can make a real difference in how diseases are studied and treated."
export const RightSideUIContext = createContext<any>({});

export const WrapSendBird: React.FC<{ children?: React.ReactNode }> = ({ children }) => {

  const { authStatus, authUser, userAttributesState, profile, isLoading, isSuccess, isError, error, userAttributesStateStatus, messagingReady, messagingData, /*isSuccessMessaging: isSuccessMessaging*/ } = useAuthProfile()
  if (authStatus === 'configuring' || isLoading)
    return (<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100vw', flexDirection: 'row' }}>
      <CircularProgress />
    </div>)

  if (isError) {
    const errMsg = 'status' in error ? `Error: ${error.status}` : 'error';
    return (<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100vw', flexDirection: 'row' }}>
      {`Oops - Sorry, AppAuth useAuthProfile ${printError(error)} error has occurred.`}
    </div>)
  }

  if (!isSuccess) {
    return null
  }

  if (messagingReady) {
    return (<>
      <SendbirdProvider appId={messagingData?.appId} userId={messagingData.userCreationResults.id?.toString()} accessToken={messagingData.userCreationResults.secret1} colorSet={chatColors}>
        {children}
      </SendbirdProvider> </>)
  }

  return (<>{children}</>)
}

interface AppProps { childComponent?: React.ReactNode, deleteProfile?: boolean, showProfileFromUrl?: boolean, dataDeletionInstructions?: boolean, messages?: boolean, callback?: boolean, out?: boolean, noBanner?: boolean }
export const App: React.FC<AppProps> = ({ noBanner = false, childComponent, deleteProfile, showProfileFromUrl, dataDeletionInstructions, messages, callback, out }) => {
  const { authStatus, authUser, userAttributesState, profile, isLoading, isSuccess, isError, error, userAttributesStateStatus, messagingReady, messagingData, /*isSuccessMessaging: isSuccessMessaging*/ deleteTriggered } = useAuthProfile()
  //const [sendBirdReady, setSendBirdReady] = useState(false)
  const location = useLocation();
  const dispatch = useDispatch()
  const showDeleteAccountState = useSelector((state: any) => state.dialog.showDeleteAccount);
  const { condition, baseName } = React.useContext<Condition>(coonditionContext);
  const [patientPinPNG, setPatientPinPNG] = useState<string | null>(null);
  React.useEffect(() => {
    const fetchImage = async () => {
      // if (baseName) {
      //const path = await loadImage(`../../assets/patienticon${baseName}.png`);
      let path = await loadImage(`patienticon${baseName}.png`, `patienticon.png`);
      //path = await loadImage(`patienticon.png`)                
      setPatientPinPNG(path ?? await loadImage(`patienticon.png`))
      // }
    };
    fetchImage();
  }, [baseName]); // Re-run effect if baseName changes


  React.useEffect(() => {
    // Select the first meta tag with a name attribute
    const metaTag = document.querySelector('meta[name]');
    if (metaTag) {
      metaTag.setAttribute("name", `${condition} Patient Connect`);
      metaTag.setAttribute("content", `${condition} Patient Connect`);
    }
    document.title = `${condition} Patient Connect`;
    const link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
    if (link && patientPinPNG) {
      link.href = patientPinPNG as string; // Set the imported icon dynamically
    }
  }, [patientPinPNG]);


  React.useEffect(() => {
    const updateManifest = () => {
      const manifest = {
        name: `${condition} Patient Connect`,//location.pathname === "/dashboard" ? "Dashboard App" : "My React App",
        short_name: "PatientConnect`",
        start_url: window.location.href,//location.pathname,
        display: "standalone",
        //theme_color: location.pathname === "/dashboard" ? "#000000" : "#ffffff",
        background_color: "#ffffff",
        ...patientPinPNG ? {
          icons: [
            {
              src: patientPinPNG as string,//location.pathname === "/dashboard" ? "/icon-dark.png" : "/icon-light.png",
              type: "image/png",
              sizes: "192x192"
            }
          ]
        } : {}
      };
      console.log(manifest)
      const blob = new Blob([JSON.stringify(manifest)], { type: "application/json" });
      const manifestURL = URL.createObjectURL(blob);

      let link = document.querySelector('link[rel="manifest"]') as HTMLLinkElement;
      if (!link) {
        link = document.createElement("link");
        link.rel = "manifest";
        document.head.appendChild(link);
      }
      link.href = manifestURL;
    };

    // const updateManifestViaBackend = () => {
    //   if (!patientPinPNG) return;

    //   // Create URL parameters
    //   const params = new URLSearchParams({
    //     condition: condition,
    //     icon: patientPinPNG,
    //     page: window.location.href,
    //     t: Date.now().toString() // Cache-busting parameter
    //   });

    //   // Construct the manifest URL
    //   const manifestUrl = `/api/manifest?${params.toString()}`;

    //   // Update or create the manifest link
    //   let link = document.querySelector('link[rel="manifest"]') as HTMLLinkElement;
    //   if (!link) {
    //     link = document.createElement("link");
    //     link.rel = "manifest";
    //     document.head.appendChild(link);
    //   }
    //   link.href = manifestUrl;

    //   console.log("Updated manifest link to:", manifestUrl);
    // };

    const addiOSNetaTags = () => {
      if (patientPinPNG) {
        // Set apple-touch-icon
        let touchIcon = document.querySelector('link[rel="apple-touch-icon"]');
        if (!touchIcon) {
          touchIcon = document.createElement("link");
          touchIcon.setAttribute("rel", "apple-touch-icon");
          document.head.appendChild(touchIcon);
        }
        touchIcon.setAttribute("href", patientPinPNG);

        // Set app name
        let appTitle = document.querySelector('meta[name="apple-mobile-web-app-title"]');
        if (!appTitle) {
          appTitle = document.createElement("meta");
          appTitle.setAttribute("name", "apple-mobile-web-app-title");
          document.head.appendChild(appTitle);
        }
        appTitle.setAttribute("content", `${condition} Patient Connect`);

        // Set capable flag
        let capable = document.querySelector('meta[name="apple-mobile-web-app-capable"]');
        if (!capable) {
          capable = document.createElement("meta");
          capable.setAttribute("name", "apple-mobile-web-app-capable");
          document.head.appendChild(capable);
        }
        capable.setAttribute("content", "yes");
      }
    }
    if (patientPinPNG) {
      updateManifest();
      addiOSNetaTags()
    }
  }, [patientPinPNG, condition])//location.pathname]);

  React.useEffect(() => {
    if (callback) {
      console.log("callback auth status", authStatus)

      if (authStatus === 'unauthenticated') {
        console.log("Checking URL parms for re-logging in callback auth status", authStatus, "location", location)
        console.log("userAttributesState", userAttributesState)
        const searchParams = new URLSearchParams(location.search);
        console.log("searchParams", searchParams)
        searchParams.forEach((value, key) => {
          console.log(key, value);
          if (key === 'code') {
            console.log('Code Found', value);
            try {
              console.log("URL CODE parms found - Re-logging in with FB")
              //console.log("Profile", profile, "authUser", authUser, "userAttributesState", userAttributesState,)
              dispatch(setPublicPinClicked(false))
              dispatch(showCareCenters(false));
              dispatch(showWarriors(false));
              setDisclaimerCookie()
              dispatch(showDisclaimer(false));
              signInWithRedirect({ provider: 'Facebook' })
              //const user = signInWithRedirect({ provider: 'Facebook' })
              //console.log("user", user)  
            } catch (error) {
              // add error handling here
              console.log(error);
            }
          }
        });
        if (searchParams.size === 0) {

        } else {
          console.log("Url parms found - NEED to relog in")
          //const user = signInWithRedirect({ provider: 'Facebook' })
        }
      }
    }
  }, [authStatus])

  if (callback) {
    //console.log("CALLBACK")
  }
  //console.log('REACT_APP_CE_PRIVATE_KEY', process.env.REACT_APP_CE_PRIVATE_KEY)
  //console.log('REACT_APP_CE_PROJECT_ID',process.env.REACT_APP_CE_PROJECT_ID)

  // if (childName !== "AppAuth" || out) {
  //   //dispatch(setPublicPinClicked(false))   
  //   dispatch(activateMarker({ id: null, disableHover: false }))
  // }

  React.useEffect(() => {
    if (showProfileFromUrl)
      dispatch(showProfile({ isForced: true }))
  }, [showProfileFromUrl])

  React.useEffect(() => {
    if (deleteProfile)
      dispatch(showDeleteAccount(true))
  }, [deleteProfile])

  React.useEffect(() => {
    if (dataDeletionInstructions) {
      dispatch(showDataDeletionInstructions(true))
    }
  }, [dataDeletionInstructions])
  React.useEffect(() => {
    if (noBanner) {
      dispatch(showBanner(false))
    }
  }, [])


  if (showDeleteAccountState) {
    return (<>
      <NavBar1 />
      <ConfirmDeleteProfile /></>)

  }



  if (authStatus === 'configuring' || isLoading)
    return (<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100vw', flexDirection: 'row' }}>
      <CircularProgress />
    </div>)

  if (isError) {
    const errMsg = 'status' in error ? `Error: ${error.status}` : 'error';
    return (<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100vw', flexDirection: 'row' }}>
      {`Oops - Sorry, AppAuth useAuthProfile ${printError(error)} error has occurred.`}
    </div>)
  }
  if (!isSuccess) {
    return (<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100vw', flexDirection: 'row' }}>
      {`Oops - Sorry, AppAuth useAuthProfile has not succeeded.`}
    </div>)
  }
  //NOT wrapping chatmain instead because of the open channel
  //   <WrapSendBird  >
  //   {childComponent ? <>{childComponent}</> : <AppBody />}
  // </WrapSendBird>


  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: { xs: '100vh', sm: '100vh', md: '100vh' },
        //minHeight: '300vh',
        //backgroundColor: 'blue'
      }}>
      <RightSideUIContext.Provider value={{ linkData: location.state, deleteProfile: deleteProfile, messages: messages, callback: callback, messagingReady }}>
        <NavBar1 />
        <Box sx={{
          display: 'flex', width: '100%', //backgroundColor: 'red',
          flexGrow: 1,
          //height: { xs: '1500px', sm: '1500px', md: 'auto', lg: 'auto' }, // Set fixed height for xs screens, and auto height for md and lg screens
          // Allow Element 2 to grow on md and lg screens
        }}>
          <WrapSendBird  >
            {childComponent ? <>{childComponent}</> : <AppBody />}
          </WrapSendBird>

        </Box>
      </RightSideUIContext.Provider>
    </Box>
  );
}

export const selectLastgetSearchByStoryyStatusArgsAndData = createSelector([(state: any) => {
  return state.aiAnalytics.queries
}],

  (queries) => {
    const lastQueryKey = Object.keys(queries).filter((key) => key.startsWith('getSearchByStory')).pop();
    const argsString = lastQueryKey ? lastQueryKey.slice(lastQueryKey.indexOf('(') + 1, lastQueryKey.lastIndexOf(')')) : null;
    const args = argsString ? JSON.parse(argsString) : undefined;
    const status: QueryStatus | undefined = undefined//lastQueryKey ? queries[lastQueryKey]?.status : undefined
    // Safely return data from the last query, if it exists
    const data = lastQueryKey ? queries[lastQueryKey]?.data as any | undefined : undefined
    return { status: status, args: args, data: data }
  })

const AppBody1: React.FC = () => {
  const searchState = useSelector((state: any) => state.search, (prev, next) => {
    if (!next.status) return true
    if (!next.status.isSuccess) return true
    if (!prev.center) return false
    return prev.formattedSearchTerm === next.formattedSearchTerm //&& ( prev.showProfileDialog === next.showProfileDialog  )
  });

  const { authStatus, profile, isLoading, isSuccess, isError, error, userAttributesStateStatus, isSuccessMessaging: isSuccessMessaging } = useAuthProfile()

  const showDisclaimerState = useSelector((state: any) => state.dialog.showDisclaimer)
  const showProfileState = useSelector((state: any) => state.dialog.showProfile)
  const showSurveyState = useSelector((state: any) => state.dialog.showSurvey)
  const showDeleteAccountState = useSelector((state: any) => state.dialog.showDeleteAccount);
  const showProfileToComplete = profile ? profile.zipcode === null || profile.communicate_with_others === null || !profile.provide_information_for_research === null || !profile.receive_emails === null : false
  const isProfileVisible = (showProfileState || showProfileToComplete) && !showDeleteAccountState && authStatus === 'authenticated'
  const isSurveyVisible = (showSurveyState ? (!showSurveyState.isExited || showSurveyState.isForced || showSurveyState.isForcedFromSurvey) : false) && !showDeleteAccountState && authStatus === 'authenticated'
  const dispatch = useDispatch()
  const isDisclaimerCookie = getDisclaimerCookie()
  const { messages: ShowChatRightSideUI, sendBirdReady } = useContext(RightSideUIContext);
  const { showChat } = useSelector((state: any) => state.dialog.showDirectChat)
  //const [isBottomSheetReady, setIsBottomSheetReady] = useState(false);
  const [hasSnapped, setHasSnapped] = useState(false)
  const bottomSheetRef = useRef<BottomSheetRef | null>(null)
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  //const searchStoryState: any = useSelector<any>(state => state.search);
  const lastgetSearchByStoryyStatusArgsAndData = useSelector((state: any) => selectLastgetSearchByStoryyStatusArgsAndData(state));
  const showBannerState = useSelector((state: any) => state.dialog.showBanner)
  const navigate = useNavigate();
  const { condition, baseName } = React.useContext<Condition>(coonditionContext);
  React.useEffect(() => {
    if (isSuccess) {
      //console.log(authStatus, profile.name_public)
      dispatch(setSearch({ lat: profile.lat, lng: profile.lng, formattedsearchterm: profile.formattedsearchterm }));
      //leocenter no need marker does it? dispatch(setCenter({ lat: profile.lat, lng: profile.lng })) 


      // problem here???
      //dispatch(activateMarker({ id: profile.iam_id, disableHover: true }))
      /*if (isDisclaimerCookie && isSuccess && authStatus === 'authenticated')// && isSurveyVisible)
        dispatch(showSurvey({ isForced: false }));*/
    }
  }, [profile])

  React.useEffect(() => {
    if (bottomSheetRef.current && bottomSheetRef.current && !hasSnapped) {
      // Delay the snapTo call to ensure the sheet is ready
      setTimeout(() => {
        if (bottomSheetRef.current) {
          bottomSheetRef.current.snapTo(window.innerHeight * 0.2);
          setHasSnapped(true)
        }
      }, 500); // Adjust the delay as needed
    }
  }, [bottomSheetRef.current, hasSnapped]); // needs both states to prevent infinite rendering

  React.useEffect(() => {
    if (bottomSheetRef.current && bottomSheetRef.current && lastgetSearchByStoryyStatusArgsAndData.status === QueryStatus.fulfilled
      && lastgetSearchByStoryyStatusArgsAndData?.data?.length > 0) {//searchStoryState.storySearch.complete) {
      if (bottomSheetRef.current) {
        bottomSheetRef.current.snapTo(window.innerHeight * 0.85);
      }
    }
  }, [lastgetSearchByStoryyStatusArgsAndData.status === QueryStatus.fulfilled]);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(showBanner(false));
    }, 20000); // Auto-hide after 3 seconds
    return () => clearTimeout(timer); // Cleanup timer on unmount
  }, []);


  if (isError) {
    const profileError = error.data ? error.data.error : error.message ?? ''

    if (profileError && profileError.includes("Key")) {
      //console.log("Profile error contains 'Key'.");
    } else {
      //console.log("Profile error does not contain 'Key' or is null.");
    }
    const keyVialoation = profileError && profileError.includes("Key")
    return (<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', width: '100vw' }}>
      <Box>
        {!keyVialoation && <Typography> Profile {profileError} </Typography>}
        {keyVialoation && <Typography> Invalid Profile:  remove profile before creating a new one
          <Button href={`/map/${baseName}/Sign-In`}>Login</Button></Typography>}
      </Box>
    </div>)
  }



  if ((!isSuccess && !isError) || !profile || searchState.status.isUnInitialized || (!userAttributesStateStatus.isSuccess && authStatus !== 'unauthenticated'))
    return (<><CircularProgressCentered /></>)




  return (
    (ShowChatRightSideUI) ?
      (isSuccessMessaging || authStatus === 'unauthenticated') ? <ChatMain /> : <Alert severity="error">Oops... Error occurred while initiating messaging feature.  Please try again later.</Alert>
      :
      <>
        <Grid container sx={{
          width: '100%',
          //flexGrow: 1,
          //height:  '100%',
          height: '100%', // Set different heights for different screen sizes
          //flexGrow: { xs: 0, sm: 0, md: 1 }, // Set different flex grow values for different screen sizes
          //backgroundColor: 'purple' 
        }}  >
          <Grid item xs={0} sm={0} md={5} lg={4} xl={4} sx={{ flexGrow: 1 }}>
            {/*<Resources />*/}
            <Box sx={{ display: { xs: 'none', sm: 'none', md: 'flex' }, height: '100%', width: '100%' }}>
              <LeftDrawer />
            </Box>
            <BottomSheet
              id="bottomsheet"
              open={isSmallScreen}
              blocking={false}
              scrollLocking={false}
              expandOnContentDrag={true}
              defaultSnap={({ maxHeight }) => {
                if (!hasSnapped) {
                  return maxHeight * 0.85
                } else {
                  return maxHeight * 0.2
                }
              }}
              skipInitialTransition={true}
              snapPoints={({ maxHeight }) => [
                maxHeight * 0.85,
                maxHeight * 0.2,
                maxHeight * 0.1,
              ]}
              ref={(ref) => {
                bottomSheetRef.current = ref;
                if (ref !== null) {
                  //if( !isBottomSheetReady) setIsBottomSheetReady(true);
                }
              }}
            >
              <LeftDrawer bottomSheet={bottomSheetRef} />
            </BottomSheet>

          </Grid>
          <Grid item xs={12} sm={12} md={7} lg={8} xl={8} sx={{ position: 'relative' }}>
            {(false && condition === 'Bladder Cancer') &&
              <Collapse in={showBannerState}>
                <Alert severity="info" icon={false} sx={{ position: 'absolute', zIndex: 1300, top: 10, left: 15, right: { xs: 15, sm: 110 }, backgroundColor: '#052D51', color: 'white', borderRadius: '10px' }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
                    <Box sx={{ display: 'flex', alignItems: 'flex-start', marginBottom: '0px' }}>
                      <ScienceOutlined sx={{ marginRight: '10px', color: 'white' }} />
                      <Typography variant="subtitle2" >{bannerTitle}</Typography>
                    </Box>
                    <IconButton onClick={() => dispatch(showBanner(false))} sx={{ color: 'white' }}><CloseIcon /></IconButton>
                  </Box>
                  <Stack direction="row" sx={{ width: '100%', alignItems: "flex-end", flexWrap: { xs: 'wrap', sm: 'nowrap', md: 'nowrap', lg: 'nowrap' } }}>
                    <Typography variant="caption" >
                      {bannerBBody}
                    </Typography>
                    <Box sx={{ marginTop: { xs: '10px', sm: '0' }, marginLeft: { xs: '0', sm: '5px' }, flexShrink: 0, }}>

                      <Button variant="contained"  {...bannerCTAUrl ? { component: "a", href: bannerCTAUrl, target: "_blank", rel: "noopener noreferrer" } : { onClick: () => navigate(bannerUrl) }} sx={{ textTransform: 'none', flexShrink: 0, backgroundColor: 'white', color: '#052D51', borderRadius: '20px', fontSize: '12px', }}>
                        Learn more
                      </Button>
                    </Box>
                  </Stack>
                </Alert>
              </Collapse>
            }

            <Box sx={{
              width: '100%',
              display: 'flex',
              position: { xs: 'fixed', sm: 'fixed', md: 'static', lg: 'static' },
              alignContent: 'center',
              alignItems: 'center',
              justifyContent: 'center',
              //backgroundColor: 'red',
              height: '100%',
              overflow: 'hidden'
            }}>
              {!showChat && <MapUI authStatus={authStatus} profile={profile} />}
              {(sendBirdReady && showChat) && <SendbirdChatButton />}

            </Box>
          </Grid>
        </Grid>
        {(!isDisclaimerCookie && showDisclaimerState) && <Disclaimer />}

        {(isDisclaimerCookie && showProfileState) && <ProfileWizard />}

        <DataDeletionInstructions />
      </>

  )

}

const MapUI1: React.FC<{ authStatus: any, profile: any }> = ({ authStatus, profile }) => {
  const searchState = useSelector((state: any) => state.search, (prev, next) => {
    if (!next.status) return true
    if (!next.status.isSuccess) return true
    if (!prev.center) return false
    return prev.formattedSearchTerm === next.formattedSearchTerm //&& ( prev.showProfileDialog === next.showProfileDialog  )
  });

  const [alertOpen, setAlertOpen] = useState(true);

  return (
    <>
      {(authStatus === "authenticated" && profile.is_completed) &&
        <Paper
          elevation={2}
          sx={{
            backgroundColor: 'white',
            position: {
              xs: 'absolute', // theme.breakpoints.up('xs')
              sm: 'absolute', // theme.breakpoints.up('sm')
              md: 'absolute', // theme.breakpoints.up('md')
            },
            zIndex: 1,
            top: {
              xs: '90px', // theme.breakpoints.up('xs')
              sm: '90px', // theme.breakpoints.up('sm')
              md: '90px', // theme.breakpoints.up('md')
            },
            right: {
              xs: '10px', // theme.breakpoints.up('xs')
              sm: '10px', // theme.breakpoints.up('sm')
              md: '10px',
            }
          }}
        >

          <Stack direction="column" gap={0}>
            <CenterMapHome profileLat={profile.lat} profileLng={profile.lng} id={profile.id} />
            <Divider sx={{ width: '100%' }} />
            <AddPinChip id="map_add_pin" chipText={'Add my Pin'} />
          </Stack>
        </Paper>
      }

      <MapBars id={profile.id}
        lat={searchState.center.lat}
        lng={searchState.center.lng}
        profileLat={profile.lat}
        profileLng={profile.lng}
        formattedSearchTerm={searchState.formattedSearchTerm}
        zipCode={searchState.zipCode}
        authStatus={authStatus} />
      <Map1 authStatus={authStatus} profile={profile} />
    </>)
}
const Map1 = React.memo(Map);
export const AppBody = React.memo(AppBody1);
const MapUI = React.memo(MapUI1);
//export default App;
