import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { //chatApi, ChatUser, DirectChatUsers,
    setChatCount, useGetChatUserQuery/*, useGetUserFromChatQuery*/
} from '../../features/ChatSlice';
import { FetchBaseQueryError, skipToken } from '@reduxjs/toolkit/query';
//import { ChatEngine, getOrCreateChat } from 'react-chat-engine'
import { CircularProgressCentered } from '../../app/sharedcomponents';
import { Calculate } from '@mui/icons-material';
//import { FetchBaseQueryError, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
//import { isFetchBaseQueryError, isErrorWithMessage } from './features/profilesSlice';
import { useAuthProfile } from "../../app/hooks"
//import {DirectChat} from "./DirectChat"
//import { useMultiChatLogic, MultiChatSocket, MultiChatWindow } from "react-chat-engine-advanced";
import { Box, Link } from '@mui/material';

// import ListItemButton from '@mui/material/ListItemButton';
// import Divider from '@mui/material/Divider';
// import ListItemText from '@mui/material/ListItemText';
// import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack';
// import TextField from '@mui/material/TextField';
// import Button from '@mui/material/Button';
// import Drawer from '@mui/material/Drawer';

//import { getAIProfileMessageSummary } from "../../features/dataProcessingSlice"
import { SummarizedAIProfile } from "../AI/SummarizedAIProfile"
import FollowTheSignsIcon from '@mui/icons-material/FollowTheSigns';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

import { Container, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { LinearProgress } from '@mui/material';
import { ReactComponent as AISVG } from '../../assets/ai_star.svg';

import { signOut, signInWithRedirect } from "aws-amplify/auth";
import { ReactComponent as FacebookIcon } from '../../assets/facebook.svg'
import TextsmsOutlinedIcon from '@mui/icons-material/TextsmsOutlined';
import { LoadingButton } from '@mui/lab';
import DeleteIcon from '@mui/icons-material/Delete';
import Modal from '@mui/material/Modal';

//import { OptionsSettings } from 'react-chat-engine-advanced';
import { useLocation } from 'react-router-dom';
import { Chats } from './Chats';
import { NoChatsMessage } from './NoChatsMessage';
import { AppDispatch } from '../../app/store';
import { printError } from '../../app/utils';
import { SignInSignUpUI } from '../iam/Signin';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import useSendbirdStateContext from '@sendbird/uikit-react/useSendbirdStateContext';
import { setAnalyticUsersClicked } from '../../features/dataProcessingSlice';
import { WrapSendBird } from '../../App';




export interface ChatCountValueAndSet {
    setChatCount: ActionCreatorWithPayload<number, "chatCount/setChatCount">//refactor React.Dispatch<React.SetStateAction<number>>
    chatCount: number
}
export const prodChatUserInTestMessage = "PROD Chat users can not chat in Test; Chats with PROD chat users not allowed in TEST.  Please delete your account and log in as a new user in TEST and chat with test (Member id > 10000) Users "
export const IsProdChatUserInTest: (id: number, messageToId: number | undefined) => boolean = (id, messageToId) => {
    return false
    //  //ussing separate app for testing
    // const environmentNODE_ENV: string = process.env.NODE_ENV || 'development';
    // const environmentREACT_APP_ENV: string = process.env.REACT_APP_ENV || 'test'

    // if (environmentNODE_ENV !== 'development' && environmentREACT_APP_ENV !== 'test') { //environmentREACT_APP_ENV gets set build:test script
    //     return false
    // }
    // return id < 10000 || (messageToId ? messageToId < 10000 : false)
}
//export const ChatMain: React.FC<{ messageToIamId: string }> = ({ messageToIamId }) => {
export const ChatMain: React.FC = () => {

    // const globalStore = useSendbirdStateContext();
    // const currentUserId = globalStore?.stores?.userStore?.user?.userId;
    let root = document.getElementById('root');
    root?.style.setProperty('position', 'fixed', 'important');
    root?.style.setProperty('width', '100%', 'important')

    const location = useLocation();
    const chatAndUserDataFromState = location.state ? (location.state as { chatAndUserData: any }).chatAndUserData : undefined;
    const { authStatus, profile: loggedInUserProfile, isLoading: isLoadingLoggedInUserProfile, isSuccess: isSuccessLoggedInUserProfile, isError: isErrorLoggedInUserProfile, error: errorLoggedInUserProfile, userAttributesStateStatus, isSuccessMessaging, isUninitializedMessaging, messagingReady } = useAuthProfile()
    const publicPinSelected: number = (loggedInUserProfile?.id && chatAndUserDataFromState?.chat) ? (chatAndUserDataFromState.chat.is_super ? undefined : chatAndUserDataFromState.chat.members.find((m: any) => m.metadata.id !== loggedInUserProfile?.id)?.metadata.id) : undefined
    const isLoggedInUserProfile = isSuccessLoggedInUserProfile ? (publicPinSelected ? loggedInUserProfile.id === publicPinSelected : true) : undefined//refactorconst isLoggedInUserProfile = isSuccessLoggedInUserProfile ? (publicPinSelected ? loggedInUserProfile.iam_id === publicPinSelected : true) : undefined
    //refactor const aimIdsArg:DirectChatUsers = (isSuccessLoggedInUserProfile && authStatus === 'authenticated' && loggedInUserProfile) ?
    //     { iamId: loggedInUserProfile.iam_id, userName: loggedInUserProfile.name_public, id: loggedInUserProfile.id, ...!isLoggedInUserProfile ? { messageToIamId: publicPinSelected } : {} }
    //     : skipToken   
    // const environment: string = process.env.NODE_ENV || 'development';
    // console.log(`Environment: ${environment}`)
    // const isProdhatUserInTest = environment.toLowerCase() === 'development' && isSuccessLoggedInUserProfile && authStatus === 'authenticated' && loggedInUserProfile ?
    //     loggedInUserProfile.id < 10000 || (publicPinSelected < 10000 && !isLoggedInUserProfile) : undefined

    const aimIdsArg = (!chatAndUserDataFromState && isSuccessLoggedInUserProfile && authStatus === 'authenticated' && loggedInUserProfile) ?
        (IsProdChatUserInTest(loggedInUserProfile.id, !isLoggedInUserProfile ? publicPinSelected : undefined) ? skipToken : (
            { /*iamId: loggedInUserProfile.iam_id,*/ userName: loggedInUserProfile.name_public, id: loggedInUserProfile.id, ...!isLoggedInUserProfile ? { messageToId: publicPinSelected } : {} }
        ))
        : skipToken
    const { data: chatAndUserData1, isLoading: isLoadingGetChatUser, isSuccess: isSuccessGetChatUser, isError: isErrorGetChatUser, error: errorGetChatUser, refetch } = useGetChatUserQuery(aimIdsArg);

    const isProdChatUserInTest = IsProdChatUserInTest(loggedInUserProfile.id, !isLoggedInUserProfile ? publicPinSelected : undefined)

    const componentRef = useRef<HTMLDivElement>(null);

    const dispatch = useDispatch<AppDispatch>()
    useEffect(() => { dispatch(setAnalyticUsersClicked(false)) })

    useEffect(() => {
        if (chatAndUserData1?.isSuccess && chatAndUserData1?.userChats) {
            setChatCount(chatAndUserData1?.userChats?.length)
        }
    }, [chatAndUserData1?.isSuccess, chatAndUserData1?.userChats?.length])

    //const waitForChat = ( isSuccessLoggedInUserProfile && !isLoggedInUserProfile) ? chatAndUserData.chatData ? false : true : false


    if (authStatus === 'unauthenticated') {
        return (<UnAuthorizedMessage />)

    }



    if (isErrorGetChatUser) {//!messagingReady && isSuccessMessaging && isUninitializedMessaging ) {
        const msg = printError(errorGetChatUser)
        if (msg.includes("Not Chat User yet")) {
            return (<NoChatsMessage />)
        }
    }

    if (isErrorLoggedInUserProfile || isErrorGetChatUser) {//||  !loggedInUserProfile){
        return (<>
            {isErrorGetChatUser && <>{`Oops - Sorry, Chat error ${printError(errorGetChatUser)} has occurred.`}</>}
            {isErrorLoggedInUserProfile && <>{`Oops - Sorry, Chat Error${printError(errorLoggedInUserProfile)} has occurred.`}</>}

        </>)
    }

    if ((!chatAndUserDataFromState && (!isSuccessGetChatUser || isLoadingGetChatUser)) || isLoadingLoggedInUserProfile || !messagingReady) {//||  !loggedInUserProfile){
        return (<><CircularProgressCentered /></>)
    }


    if (isProdChatUserInTest) {
        return (<><h3>{prodChatUserInTestMessage}</h3></>)
    }

    const chatAndUserData = chatAndUserDataFromState ? chatAndUserDataFromState : isSuccessGetChatUser ? chatAndUserData1 : undefined
    const user = { username: chatAndUserData.userCreationResults.username, secret: chatAndUserData.userCreationResults.secret1 }

    if (isLoggedInUserProfile && !chatAndUserData.expectedUserChatCount) {//!chatAndUserData.chat && !chatAndUserData?.userChats?.length) {
        return (<NoChatsMessage />)
    }


    return (
    
            <Chats
                chatAndUserData={chatAndUserData}
                id={loggedInUserProfile.id}
                height={100}
                direct_chat={!isLoggedInUserProfile}
            />
    
    )
}
//  <WrapSendBird  >
// <Chats
//     chatAndUserData={chatAndUserData}
//     id={loggedInUserProfile.id}
//     height={100}
//     direct_chat={!isLoggedInUserProfile}
// />
// </WrapSendBird  > 
export const UnAuthorizedMessage: React.FC = () => {

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: "center",
            marginLeft: 'auto',
            marginRight: 'auto',
            maxHeight: '90vh'
        }}>

            <SignInSignUpUI >
                <Stack 
                    direction="column" display="flex" alignItems="center" justifyContent="center" spacing={2}
                    sx={{ border: 1, borderColor: '#DEDEDE', borderRadius: 2, p: 1 }}
                >
                    <TextsmsOutlinedIcon fontSize='large' />
                    <Typography align='center' variant='subtitle1' >Please sign in to use Patient Connect Messaging</Typography>
                </Stack>
            </SignInSignUpUI>

        </Box>)
}
