import { configureStore } from '@reduxjs/toolkit';
//import loginReducer from '../features/loginSlice';
import searchSliceReducer from '../features/searchSlice';
import clickContextReducer from '../features/clickContextSlice';
import loadDataReducer from '../features/CareCenterDataSlice';
import filterReducer from '../features/filterSlice';
import activeMarkersReducer from '../features/activeMarkers'
import {profileApi }from '../features/profilesSlice';
import userAttributesReducer from '../features/userAttributesSlice'
import dialogReducer from '../features/dialogSlice'
import {utilityApi }from '../features/utilitySlice';
import { surveyApi } from '../features/surveysSlice';
import dataProcessingSliceReducer from '../features/dataProcessingSlice';
import { chatApi, chatCount } from '../features/ChatSlice';
import { institutionsApi } from '../features/institutionsSlice';
import { aiAnalyticsApi } from '../features/aiAnalytics';
import { resourcesApi, lazyResouceCompanionReducer  } from '../features/resourcesSlice';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { clinicalTrialssApi } from '../features/clinicalTrials';

//import { chrApi } from '../features/surveysSlice';

export const store = configureStore({
  reducer: {
    [surveyApi.reducerPath]: surveyApi.reducer,
    search: searchSliceReducer,
    dataProcessing: dataProcessingSliceReducer,
    clickcontext: clickContextReducer,
    loaddata: loadDataReducer,
    filter: filterReducer,
    activeMarkers: activeMarkersReducer, 
    [utilityApi.reducerPath]: utilityApi.reducer,
    [profileApi.reducerPath]: profileApi.reducer,
    [chatApi.reducerPath]: chatApi.reducer,
    chatCount: chatCount.reducer,
    [institutionsApi.reducerPath]: institutionsApi.reducer,
    [clinicalTrialssApi.reducerPath]: clinicalTrialssApi.reducer,
    [aiAnalyticsApi.reducerPath]: aiAnalyticsApi.reducer,
    [resourcesApi.reducerPath]: resourcesApi.reducer,
    userattributes: userAttributesReducer,
    dialog: dialogReducer,
    lazyResouceCompanion: lazyResouceCompanionReducer
  },
  middleware: getDefaultMiddleware =>
  getDefaultMiddleware()
  .concat(profileApi.middleware)
  .concat(aiAnalyticsApi.middleware)
  .concat(chatApi.middleware)
  .concat(surveyApi.middleware)
  .concat(utilityApi.middleware)
  .concat(institutionsApi.middleware)
  .concat(resourcesApi.middleware)
  .concat(clinicalTrialssApi.middleware)
});
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
