import { Box, Button, Chip, Dialog, Divider, FormControlLabel, Popper, Stack, Switch, SxProps, Theme, Typography, useTheme } from "@mui/material";
import React, { ChangeEvent, useState } from "react";
import { GetPinName } from "./MapFilter";
import { GetPinByCategoryAndType, GetUserTypePin } from "../MessageProfile";
import { GetIntitutionTypePinPNG, InstitutionTypes } from "../markers/InstitutionMarkers";
import { showCareCenters, showWarriors } from "../../features/filterSlice";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../app/store";
import { setPublicPinClicked } from "../../features/dataProcessingSlice";
import { activateMarker } from "../../features/activeMarkers";
import { Condition, coonditionContext } from "../..";
//import { showMapFilters } from "../../features/dialogSlice";

export const userPinTypes: string[] = ["P", "C", "M", "O"]
export const pinIconStyle = { width: '26px', height: '26px' }
// export const PinChip: React.FC<string> = (pinType) => {
//     const iconStyle = { width: '28px', height: '28px' }; // Adjust the size as needed
//     const userPinAndBackgroundColor = GetUserTypePin(pinType, { style: iconStyle })
//     const theme = useTheme();

//     return (<Chip
//         label={GetPinName(pinType)}
//         size='medium'
//         icon={userPinAndBackgroundColor.icon}
//         sx={{ padding: '5px', marginLeft: '8px', height: '36px', width: 'fit-content', backgroundColor: userPinAndBackgroundColor.color, fontSize: theme.typography.body2.fontSize }}
//     />)
// }
//GetUserTypePin
//GetIntitutionTypePinSVG(pinType)
export const PinFormControl: React.FC<{ pincategory: "Institution" | "User", pinType: string/*, handleChange: (event: ChangeEvent<HTMLInputElement>)=> void */ }> = ({ pincategory, pinType }) => {
    const filterState = useSelector((state: any) => state.filter, shallowEqual)
    const dispatch = useDispatch<AppDispatch>();
    function handleChange(event: ChangeEvent<HTMLInputElement>) {
        if (!event.target.checked) {
            dispatch(activateMarker({ id: null, disableHover: false }))
        }
        if (event.target.name === "Institution") {
            dispatch(showCareCenters({ type: event.target.id, isVisible: event.target.checked }));
            return
        } else {
            dispatch(showWarriors({ type: event.target.id, isVisible: event.target.checked }));
        }
    }

    const typeFilterState = pincategory === "Institution" ? filterState.showCareCenters : filterState.showWarriors
    //const checked = typeof typeFilterState === 'boolean' ? typeFilterState : ((typeFilterState[pinType] === undefined && filterState.unknownIsGood) || (typeFilterState[pinType]?true:false))
    const checked = typeof typeFilterState === 'boolean' ? typeFilterState : (pinType in typeFilterState? typeFilterState[pinType]: filterState.unknownIsGood)
    const pinData = GetPinByCategoryAndType(pincategory, pinType, { style: pinIconStyle })
    //flex: '1 1 auto',
    return (<>
        <FormControlLabel
            sx={{pl: 1,  minWidth: 0,    }}
            control={<><Switch checked={checked} onChange={(e) => handleChange( e)} id={pinType} name={pincategory} />{pinData.icon} </>}
            label={
                <Typography
                    sx={{
                        fontSize: {
                            xs: 14,
                            sm: 14,
                            md: 14,
                            lg: 12,
                        },
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: '100%',
                    }}
                >
                    {pinData.namePlural?.replace("(s)", "s")}
                </Typography>
            }
        />
    </>)
}

export const MapFilterMenu: React.FC<{ [key: string]: any }> = (props) => {
  const { condition, baseName } = React.useContext<Condition>(coonditionContext);
    const dispatch = useDispatch<AppDispatch>()
    const selectAll: (select: boolean) => void = (select) => {//(event: React.MouseEvent<HTMLButtonElement>) => void = ()=>{
        if (!select) {
            dispatch(activateMarker({ id: null, disableHover: false }))
        }
        dispatch(showCareCenters(select));
        dispatch(showWarriors(select));
    }

   
    var institutionTypesFiltered  = (condition === 'Multiple Myeloma') ? InstitutionTypes : InstitutionTypes.filter( (t: string) => t !== 'BSTP' )
    institutionTypesFiltered  = (condition === 'Bladder Cancer') ? institutionTypesFiltered : institutionTypesFiltered.filter( (t: string) => t !== 'TR' && t !=='WALK')

    return (
        <Stack {...props} sx={{pt:1,pb:1}}>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button onClick={() => selectAll(true)} >Select All</Button><Button onClick={() => selectAll(false)}>Clear All</Button>
            </Box>
            {userPinTypes.map((userPinType: string, index) => (
                <PinFormControl pincategory="User" pinType={userPinType}  key={index}/>
            ))}
            <Divider orientation="horizontal" />
            {institutionTypesFiltered.map((institutionPinType: string, index) => (                
                <PinFormControl pincategory="Institution" pinType={institutionPinType} key={-index}/>
            ))}
        </Stack>
    )
}