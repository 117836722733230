import React from "react"
import Divider from '@mui/material/Divider';
import { Button, Box, Stack, Typography, Container } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CircleIcon from '@mui/icons-material/Circle';
import { useNavigate, useSearchParams } from "react-router-dom";



export const RenderStudies: React.FC<{ questionsData: any }> = ({ questionsData }) => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams();

  function handleStudyLearnMore(e: any, index: number) {
    document.getElementById('resource_top')?.scrollIntoView({ behavior: 'smooth' })

    const category = searchParams.get('category');
    const queryParams = new URLSearchParams({
      search: questionsData.questions[index],
      category: category ?? '',//questionsData.category ?? '',
      status: questionsData.metadata[index].status,
      //contactId: questionsData.metadata[index].contactId
      eligibility: questionsData.metadata[index].eligibility,
      studyType: questionsData.metadata[index].studyType,
      compensation: questionsData.metadata[index].compensation,
      contactId: questionsData.metadata[index].contactId,
      studyCtaUrl: questionsData.metadata[index].studyCtaUrl
    });
    navigate(`/Resources?${queryParams.toString()}`);
  }
  // const openStudies = questionsData.filter( (qm: any, index: number) => qm?.status === 'Open')
  // const closedStudies = questionsData.filter( (qm: any) => qm?.status !== 'Open')
  const studies = questionsData.metadata.reduce(
    (acc: any, currentItem: any, index: number) => {
      // Condition to split by (e.g., select items with an id greater than 2)
      if (currentItem.status === 'Open') {
        acc[0].studiesData.push({ metaData: currentItem, index }); // Add to the first array (matches condition)
      } else {
        acc[1].studiesData.push({ metaData: currentItem, index }); // Add to the second array (doesn't match condition)
      }
      return acc;
    },
    [{ studiesData: [], title: 'Current Studies', isOpen: true }, { studiesData: [], title: 'Past Studies', isOpen: false }] // Initial value: two empty arrays
  );

  return (
    <>
      {studies.map((s: any, index: number) =>
        <Box key={index} sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          border: '1px solid #656EFF',
          borderRadius: '15px',
          padding: { xs: '10px', sm: '15px', md: '28px' },
          marginBottom: '8px',
        }}>
          <Box sx={{ marginTop: '0px', marginBottom: '20px', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'row', }}>
            <div>
              <CircleIcon sx={{ color: s.isOpen ? '#656EFF' : 'grey', fontSize: 12 }} />
            </div>
            <Typography variant="body1" sx={{ color: s.isOpen ? 'black' : 'grey', fontWeight: '660', marginLeft: 1 }}>{s.title}</Typography>
          </Box>
          <div>
            {s.studiesData.length > 0 ?
              s.studiesData.map((osMeta: any, index: number) => {
                const studyCtaUrl = questionsData.metadata[index].studyCtaUrl
                return (
                  <Container key={index}>
                    {index > 0 && <Divider sx={{ marginTop: '25px', marginBottom: '25px' }}></Divider>}
                    <RenderQuestionMetaData learnMoreClick={(e) => { handleStudyLearnMore(e, osMeta.index) }} metaData={osMeta.metaData} question={questionsData.questions[osMeta.index]} />
                    <Button
                      {...studyCtaUrl ? { component: "a", href: studyCtaUrl, target: "_blank", rel: "noopener noreferrer" } : { onClick: (e: any) => handleStudyLearnMore(e, osMeta.index) }}
                      variant="outlined"
                      color="primary"
                      endIcon={<ArrowForwardIcon />}
                      sx={{
                        marginTop: '25px',
                        textTransform: 'none',
                        borderRadius: '50px', // Rounded button
                        borderColor: '#656EFF', // Blue outline
                        color: '#656EFF', // Text color blue              
                      }}
                    >Learn More</Button>
                  </Container>)
              })
              : <>  <Container><RenderQuestionMetaData question={`There are no ${s.title.toLowerCase()} at this time.  Please check again later.`} />  </Container></>
            }
          </div>

        </Box>
      )}
    </>
  )
}

export const RenderQuestionMetaData: React.FC<{ question: string, metaData?: any, learnMoreClick?: (e: any) => void }> = ({ learnMoreClick = (e) => { }, metaData, question }) => {

  return (<>
    <Typography onClick={learnMoreClick} sx={{ cursor: 'pointer', marginBottom: '15px', fontWeight: 'bold', color: '#656EFF', fontSize: { xs: '16px', sm: '17px', md: '18px' } }}>{question}</Typography>
    {metaData &&
      <Stack gap={'8px'}>
        <Stack gap={'8px'}>
          <Typography sx={{ marginBottom: '0px', fontSize: { xs: '14px', sm: '14px', md: '15px' } }}><strong>Status: </strong>{metaData.status}</Typography>
          <Typography sx={{ marginBottom: '0px', fontSize: { xs: '14px', sm: '14px', md: '15px' } }}><strong>Eligibility: </strong>{metaData.eligibility}</Typography>
        </Stack>
        <Typography sx={{ marginBottom: '0px', fontSize: { xs: '14px', sm: '14px', md: '15px' } }}> <strong>Study Type: </strong>{metaData.studyType}</Typography>
        <Typography sx={{ marginBottom: '0px', fontSize: { xs: '14px', sm: '14px', md: '15px' } }}>  <strong>Compensation: {metaData.compensation}</strong>   </Typography>
      </Stack>}
  </>)
}

